import { useState } from 'react';
import styled from 'styled-components';
import { Select, SelectProps, InputLabel, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import MenuItem from '@mui/material/MenuItem';

import { Colors } from '../../../styles/colors';
import { Task } from '../../../generated/types';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button';
import { Form } from '../../atoms/Form/Form';

export const TaskManagerTextInput = ({
  defaultValue = '',
  placeholder = '',
  onSave,
}: {
  defaultValue?: string;
  placeholder?: string;
  onSave: ({ title }: { title: string }) => void;
}) => {
  const [text, setText] = useState(defaultValue || '');

  const handleSubmit = (e: any) => {
    const inputText = e.target.value.trim();
    if (e.which === 13) {
      onSave({ title: inputText });
      setText('');
    }
  };

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const handleBlur = (e: any) => {
    onSave({ title: text });
  };

  return (
    <Input
      type="text"
      placeholder={placeholder}
      autoFocus
      value={text}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleSubmit}
    />
  );
};

export const TaskManagerTextInputWithAI = ({
  defaultValue = '',
  placeholder = '',
  onSave,
}: {
  defaultValue?: string;
  placeholder?: string;
  onSave: ({ title }: { title: string }) => void;
  defaultDescription?: string;
}) => {
  const [title, setTitle] = useState(defaultValue || '');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (e?.target?.['title']?.value) {
      onSave({
        title: e.target['title'].value,
      });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.which === 13) {
      onSave({
        title: title,
      });
      setTitle('');
    }
  };

  const handleChange = (e: any) => {
    if (e.target.name === 'title') {
      setTitle(e.target.value);
    }
  };

  const handleBlur = (e: any) => {
    // console.log(e);
    // if (!newTodo) {
    //   onSave(e.target.value);
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputContainer>
        <Input
          name="title"
          type="text"
          placeholder={placeholder}
          autoFocus
          value={title}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        <InputButtonWrapper>
          <PrimaryButton size="tiny" inline={true} type="submit">
            Add
          </PrimaryButton>
        </InputButtonWrapper>
      </InputContainer>
    </form>
  );
};

export const TaskManagerFullTextInput = ({
  task,
  onSave,
  onCancel,
}: {
  task: Task;
  onSave: ({ title, description, dueDate, priority, isFullDay }: any) => void;
  onCancel: () => void;
}) => {
  const { title, description, dueDate, priority, isFullDay } = task;
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentPriority, setCurrentPriority] = useState(priority);
  const [currentDescription, setCurrentDescription] = useState(description);
  const [currentDueDate, setCurrentDueDate] = useState<Dayjs | null>(dayjs(dueDate));
  const [currentDueTime, setCurrentDueTime] = useState<Dayjs | null>(isFullDay ? null : dayjs(dueDate));

  const handleSubmit = () => {
    const isFullDay = !currentDueTime;
    const dueTime = isFullDay ? '00:00' : currentDueTime?.format('HH:mm');
    const dueDate = `${currentDueDate?.format('YYYY-MM-DD')}T${dueTime}:00.000Z`;
    onSave({
      title: currentTitle,
      description: currentDescription,
      dueDate,
      priority: currentPriority,
      isFullDay,
    });
  };

  const handleChange = (e: any) => {
    if (e.target.name === 'title') {
      setCurrentTitle(e.target.value);
    }
    if (e.target.name === 'description') {
      setCurrentDescription(e.target.value);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputContainer>
          <Input name="title" type="text" defaultValue={currentTitle || ''} autoFocus onChange={handleChange} />
          <Input
            name="description"
            type="text"
            defaultValue={currentDescription || ''}
            autoFocus
            onChange={handleChange}
          />
        </InputContainer>
        <BottomWrapper>
          <LineWrapper>
            <DatePicker
              label="Due Date"
              defaultValue={dayjs(dueDate)}
              views={['year', 'month', 'day']}
              onChange={(newValue) => setCurrentDueDate(newValue)}
            />
            <TimePicker
              defaultValue={isFullDay ? null : dayjs(dueDate)}
              label="Due time"
              onChange={(newValue) => setCurrentDueTime(newValue)}
              slotProps={{ field: { clearable: true } }}
            />
            <SelectFieldExtended
              label="Priority"
              value={currentPriority}
              onChange={(newValue) => setCurrentPriority(newValue.target.value)}
            >
              <MenuItem value="MEDIUM">Medium</MenuItem>
              <MenuItem value="LOW">Low</MenuItem>
              <MenuItem value="HIGH">High</MenuItem>
              <MenuItem value="CRITICAL">Critical</MenuItem>
            </SelectFieldExtended>
          </LineWrapper>
          <SecondaryButton size="medium" inline={true} onClick={onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="medium" inline={true} type="submit">
            Save
          </PrimaryButton>
        </BottomWrapper>
      </LocalizationProvider>
    </Form>
  );
};

const InputContainer = styled.div`
  /* padding-inline: calc(var(--right-left-global-padding) / 2); */
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  margin-bottom: 16px;
`;

const Input = styled.input`
  padding: 16px 16px 16px 60px;
  height: 65px;
  border: none;
  background: rgba(0, 0, 0, 0.003);
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.03);
  width: 100%;
  outline: ${Colors.ContrastLightest} auto 1px;
`;

const InputButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const SelectFieldExtended = styled(Select)`
  .Mui-focused {
    fieldset {
      border-left-width: 6px !important; // override inline-style
    }
  }
  .MuiFormHelperText-contained {
    margin: 0;
  }
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 0 16px;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;
