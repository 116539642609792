import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Task } from '../../../generated/types';
import { TaskManagerTextInput } from './TaskManagerTextInput';
import { IconButton } from '../../atoms/Icons/Icon';
import { DeleteIcon } from '../../atoms/Icons';
import { Colors } from '../../../styles/colors';

export const TaskManagerItem = ({
  index,
  task,
  handleCompleteTask,
  handleDeleteTask,
  handleEditTask,
}: {
  index: number;
  task: Task;
  handleCompleteTask: any;
  handleDeleteTask: any;
  handleEditTask: any;
}) => {
  const [editing, setEditing] = useState(false);
  const [isDeleleted, setIsDeleleted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(task.isCompleted);

  useEffect(() => {
    const handleEscape = (event: any) => {
      if (event.keyCode === 27) {
        setEditing(false);
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  const deletetask = async (uuid: string) => {
    setIsDeleleted(true);
    await handleDeleteTask(uuid);
  };

  const completeTask = async (event: any, uuid: string) => {
    const isCompleted = event.target.checked;
    setIsCompleted(isCompleted);
    await handleCompleteTask(uuid, isCompleted);
  };

  const updateTask = async (uuid: string, title: string) => {
    setEditing(false);
    await handleEditTask(uuid, title);
  };

  const handleDoubleClick = () => setEditing(true);

  return (
    <>
      {!isDeleleted && (
        <motion.div custom={{ delay: (index + 1) * 0.1 }} initial="hidden" animate="visible" exit="hidden">
          {/* <pre>
            {JSON.stringify(
              {
                task,
                title,
              },
              null,
              2
            )}
          </pre> */}
          {editing ? (
            <TaskManagerTextInput
              defaultValue={task.title || ''}
              onSave={(value) => {
                updateTask(task.uuid, value);
              }}
            />
          ) : (
            <Wrapper>
              <WrapperLeft>
                <Checkout
                  type="checkbox"
                  onClick={(event) => completeTask(event, task.uuid)}
                  checked={isCompleted}
                  readOnly={true}
                />
                <Label className={isCompleted ? 'completed' : ''} onDoubleClick={handleDoubleClick}>
                  {task.title}
                </Label>
              </WrapperLeft>
              <WrapperRight>
                <IconButton onClick={() => deletetask(task.uuid)}>
                  <DeleteIcon />
                </IconButton>
              </WrapperRight>
            </Wrapper>
          )}
        </motion.div>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  margin: 0;
  padding: 0;

  &:hover {
    border: 1px solid ${Colors.ContrastUltraLightest};
    svg {
      display: block;
    }
  }

  svg {
    display: none;
  }
`;

const WrapperLeft = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const WrapperRight = styled.div`
  display: flex;
  width: 56px;
`;

const Checkout = styled.input`
  position: absolute;
  opacity: 0;
  height: 64px;
  width: 48px;
  cursor: pointer;
`;

const Label = styled.label`
  cursor: pointer;
  padding: 0 0 0 52px;
  height: 64px;
  background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23949494%22%20stroke-width%3D%223%22/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  align-items: center;
  width: 100%;

  &.completed {
    background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%2359A193%22%20stroke-width%3D%223%22%2F%3E%3Cpath%20fill%3D%22%233EA390%22%20d%3D%22M72%2025L42%2071%2027%2056l-4%204%2020%2020%2034-52z%22%2F%3E%3C%2Fsvg%3E); */
  }
`;
