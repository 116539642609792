import StepperMui from '@mui/material/Stepper';
import StepMui from '@mui/material/Step';
import StepLabelMui from '@mui/material/StepLabel';

import { StepperProps } from './Stepper.types';
import { Container } from './Stepper.styles';
import styled from 'styled-components';

export const Stepper: React.FC<any> = ({ activeStep, onActionClick, steps }: StepperProps) => {
  return (
    <Container>
      <StepperMui activeStep={activeStep} alternativeLabel={true}>
        {steps.map((label, index) => (
          <StepMui key={index}>
            <StepLabelMui onClick={() => onActionClick && onActionClick(index)}>
              <StepperContainer>{label}</StepperContainer>
            </StepLabelMui>
          </StepMui>
        ))}
      </StepperMui>
    </Container>
  );
};

const StepperContainer = styled.div`
  padding: 8xp;
  display: flex;
  justify-content: center;
`;
