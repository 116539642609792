import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateAiTaskArgs } from '../../generated/types';

export const CREATE_AI_TASK = gql`
  mutation CreateAITask($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: AITaskInputData!) {
    createAITask(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid
      title
      description
      dueDate
      isFullDay
      priority
      order
      isCompleted
      isDeleted
    }
  }
`;

export const useCreateAITask = (
  options?: MutationHookOptions<Pick<Mutation, 'createAITask'>, MutationCreateAiTaskArgs>
) =>
  useMutation<Pick<Mutation, 'createAITask'>, MutationCreateAiTaskArgs>(CREATE_AI_TASK, {
    update(cache, { data }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const createAITask = data?.createAITask;
      if (!jobUuid) return;
      if (!createAITask) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          tasks(existingTasks = []) {
            const newTaskRef = cache.writeFragment({
              data: createAITask,
              fragment: gql`
                fragment NewTask on Task {
                  uuid
                  title
                  description
                  dueDate
                  isFullDay
                  priority
                  isCompleted
                  isDeleted
                }
              `,
            });
            return [...existingTasks, newTaskRef];
          },
        },
      });
    },
    ...options,
  });
