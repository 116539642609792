import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateTaskArgs } from '../../generated/types';

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $taskUuid: String!
    $data: TaskInputData!
  ) {
    updateTask(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, taskUuid: $taskUuid, data: $data) {
      uuid
      title
      description
      dueDate
      isFullDay
      order
      priority
      isCompleted
    }
  }
`;

export const useUpdateTask = (options?: MutationHookOptions<Pick<Mutation, 'updateTask'>, MutationUpdateTaskArgs>) =>
  useMutation<Pick<Mutation, 'updateTask'>, MutationUpdateTaskArgs>(UPDATE_TASK, {
    update(cache, { data: { updateTask = {} } = {} }) {
      if (!updateTask.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Task',
          uuid: updateTask.uuid,
        }),
        fields: {
          title() {
            return updateTask.title;
          },
          description() {
            return updateTask.description;
          },
          dueDate() {
            return updateTask.dueDate;
          },
          isFullDay() {
            return updateTask.isFullDay;
          },
          priority() {
            return updateTask.priority;
          },
          isCompleted() {
            return updateTask.isCompleted;
          },
        },
      });
    },
    ...options,
  });
