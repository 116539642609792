import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveNoteArgs } from '../../generated/types';

export const ARCHIVE_NOTE = gql`
  mutation ArchiveNote(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $noteUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveNote(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      noteUuid: $noteUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveNote = (options?: MutationHookOptions<Pick<Mutation, 'archiveNote'>, MutationArchiveNoteArgs>) =>
  useMutation<Pick<Mutation, 'archiveNote'>, MutationArchiveNoteArgs>(ARCHIVE_NOTE, {
    update(cache, { data: { archiveNote = {} } = {} }) {
      if (!archiveNote.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Note',
          uuid: archiveNote.uuid,
        }),
        fields: {
          isDeleted() {
            return archiveNote.isDeleted;
          },
        },
      });
    },
    ...options,
  });
