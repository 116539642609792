import { Attachment } from '@styled-icons/remix-line';
import { Attachment as AttachmentFill } from '@styled-icons/remix-fill';

import { Icon, IconProps } from './Icon';

export function AttachmentIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Attachment />
    </Icon>
  );
}

export function AttachmentFullIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <AttachmentFill />
    </Icon>
  );
}
