import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCompleteTaskArgs } from '../../generated/types';

export const COMPLETE_TASK = gql`
  mutation CompleteTask(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $taskUuid: String!
    $isCompleted: Boolean!
  ) {
    completeTask(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      taskUuid: $taskUuid
      isCompleted: $isCompleted
    ) {
      uuid
      isCompleted
    }
  }
`;

export const useCompleteTask = (
  options?: MutationHookOptions<Pick<Mutation, 'completeTask'>, MutationCompleteTaskArgs>
) =>
  useMutation<Pick<Mutation, 'completeTask'>, MutationCompleteTaskArgs>(COMPLETE_TASK, {
    ...options,
  });
