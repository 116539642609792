import { NoteProps } from './Note.types';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Paper } from '../../atoms/Paper';
import { AddIcon, DeleteIcon, EditIcon, FileIcon } from '../../atoms/Icons';
import { RichTextInputReadOnly } from '../../atoms/Form/RichTextInput/RichTextInput';
import { IconButton } from '../../atoms/Icons/Icon';
import {
  Container,
  Actions,
  Content,
  EditableContainer,
  LineWrapper,
  Title,
  RichTextWrapper,
  ContainerList,
} from './Note.styles';

export const Note: React.FC<any> = ({
  title,
  description,
  onEditClick,
  onDeleteClick,
}: React.PropsWithChildren<NoteProps>) => {
  return (
    <ErrorBoundary message="Molecules\Note">
      <Container>
        <Paper elevation={1}>
          <Actions>
            <IconButton onClick={() => onDeleteClick()}>
              <DeleteIcon size={1.2} />
            </IconButton>
            <IconButton onClick={() => onEditClick()}>
              <EditIcon size={1.2} />
            </IconButton>
          </Actions>

          <Content>
            <LineWrapper>
              <FileIcon size={1.5} />
              <Title>{title}</Title>
            </LineWrapper>
            <RichTextWrapper>
              <RichTextInputReadOnly>{description}</RichTextInputReadOnly>
            </RichTextWrapper>
          </Content>
        </Paper>
      </Container>
    </ErrorBoundary>
  );
};

export const NoteSkeleton: React.FC<any> = ({ onEditClick }: React.PropsWithChildren<NoteProps>) => {
  return (
    <ErrorBoundary message="Molecules\Note">
      <Container>
        <Paper elevation={1}>
          <EditableContainer>
            <Actions>
              <IconButton onClick={onEditClick}>
                <AddIcon size={1.5} />
              </IconButton>
            </Actions>
            <Content>Add Note</Content>
          </EditableContainer>
        </Paper>
      </Container>
    </ErrorBoundary>
  );
};

export const NotesList: React.FC<any> = ({ children }: React.PropsWithChildren<NoteProps>) => {
  return (
    <ErrorBoundary message="Molecules\NotesList">
      <ContainerList>{children}</ContainerList>
    </ErrorBoundary>
  );
};
