import { ResumeBasicFormSimpleProps } from './ResumeBasicForm.types';

import { TextInput } from '../../atoms/Form/TextInput';
import { Container, GroupItems, HalfItem } from '../ResumeCommonForm';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumeBasicFormSimple = ({ dispatch, basics }: ResumeBasicFormSimpleProps) => {
  const { name = '', jobTitle = '' } = basics;
  return (
    <ErrorBoundary message="Molecule\ResumeBasicFormSimple">
      <Container>
        <GroupItems>
          <HalfItem>
            <TextInput
              name="name"
              type="text"
              label="Name"
              placeholder="John Doe"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'basics.name', value: e?.target?.value },
                });
              }}
              value={name}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="jobTitle"
              type="text"
              label="Job Title"
              placeholder="John"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'basics.jobTitle', value: e?.target?.value },
                });
              }}
              value={jobTitle}
            />
          </HalfItem>
        </GroupItems>
      </Container>
    </ErrorBoundary>
  );
};
