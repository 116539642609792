import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${Colors.PrimaryLightest};
  padding: 16px;
  border-radius: 8px;
  flex-wrap: wrap;
  gap: 16px;
`;

export const LeftContainer = styled.div`
  flex: 3 0 300px;
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* ${media.from_mobile`
    justify-content: flex-end;
  `} */
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${Colors.Black};
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
