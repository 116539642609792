import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateNoteArgs } from '../../generated/types';

export const CREATE_NOTE = gql`
  mutation CreateNote($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: NoteInputData!) {
    createNote(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid
      title
      description
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const useCreateNote = (options?: MutationHookOptions<Pick<Mutation, 'createNote'>, MutationCreateNoteArgs>) =>
  useMutation<Pick<Mutation, 'createNote'>, MutationCreateNoteArgs>(CREATE_NOTE, {
    ...options,
  });
