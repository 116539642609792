import ReactApexChart from 'react-apexcharts';
import styled from 'styled-components';

import { Colors } from '../../../styles/colors';

import { JobDescriptionStatisticProps } from './JobDescriptionStatistic.types';
import { Container } from './JobDescriptionStatistic.styles';
import { RichTextInputReadOnly } from '../../atoms/Form/RichTextInput/RichTextInput';
import { Copy } from '../../atoms/Typography';
import { Pill, PillsList } from '../../atoms/Pill/Pill';
import { SkeletonBlock, SkeletonCircle } from '../../atoms/Skeleton';
import { Spacer } from '../../atoms/Spacer/Spacer';

export const JobDescriptionStatistic = ({ job }: React.PropsWithChildren<JobDescriptionStatisticProps>) => {
  const canSeeStats = job?.permissions?.includes('VIEW_STATS') || false;
  const canCreateCoverLetter = job?.permissions?.includes('CREATE_COVER_LETTER') || false;
  const isLoadingStatistic = !job?.aiIsProcessed;
  const requiredSkillsPercentage = job?.aiMatchingRequiredSkillsPercentage;
  const niceToHaveSkillsPercentage = job?.aiMatchingNiceToHaveSkillsPercentage;
  const softSkillsPercentage = job?.aiMatchingSoftSkillsPercentage;
  const series: number[] = [];
  const colors: string[] = [];
  const labels: string[] = [];

  if (requiredSkillsPercentage && requiredSkillsPercentage > 0) {
    series.push(requiredSkillsPercentage);
    colors.push('#778c77');
    labels.push('Required');
  }

  if (niceToHaveSkillsPercentage && niceToHaveSkillsPercentage > 0) {
    series.push(niceToHaveSkillsPercentage);
    colors.push('#B6C9B6');
    labels.push('Nice to have');
  }

  if (softSkillsPercentage && softSkillsPercentage > 0) {
    series.push(softSkillsPercentage);
    colors.push('#BEA7BE');
    labels.push('Soft');
  }

  const state = {
    series,
    options: {
      chart: {
        height: 450,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors,
      labels,
      legend: {
        show: true,
        floating: true,
        fontSize: '16px',
        position: 'left',
        offsetX: 0,
        offsetY: 15,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    <Container>
      {/* <pre>{JSON.stringify({ job }, null, 2)}</pre> */}
      {/* {canSeeStats && (
        <>
          {isLoadingStatistic ? (
            <>
              <SkeletonWrapper>
                <SkeletonCircle radius={320} style={{ marginBottom: '16px' }}></SkeletonCircle>
                <Spacer x={16} />
                <div>
                  <SkeletonBlock style={{ width: '320px', height: '48px', marginBottom: '16px' }}></SkeletonBlock>
                  <SkeletonBlock style={{ width: '640px', height: '48px', marginBottom: '16px' }}></SkeletonBlock>
                  <SkeletonBlock style={{ width: '320px', height: '48px', marginBottom: '16px' }}></SkeletonBlock>
                  <SkeletonBlock style={{ width: '640px', height: '48px', marginBottom: '16px' }}></SkeletonBlock>
                  <SkeletonBlock style={{ width: '320px', height: '48px', marginBottom: '16px' }}></SkeletonBlock>
                </div>
              </SkeletonWrapper>
            </>
          ) : (
            <HeaderWrapper>
              <HeaderLeftWrapper>
                <ReactApexChart options={state.options} series={state.series} type="radialBar" height={390} />
              </HeaderLeftWrapper>
              <HeaderRightWrapper>
                <Label>Job required skills</Label>
                <PillsList>
                  {job?.aiRequiredSkills?.map((skill, index) => (
                    <Pill key={index} isSmall={false} color={Colors.PrimaryDarkest} isBold={true}>
                      {skill}
                    </Pill>
                  ))}
                </PillsList>
                <Label>Job nice to have skills</Label>
                <PillsList>
                  {job?.aiNiceToHaveSkills?.map((skill, index) => (
                    <Pill key={index} isSmall={true} color={Colors.Primary} isBold={true}>
                      {skill}
                    </Pill>
                  ))}
                </PillsList>
                <Label>Job soft skills</Label>
                <PillsList>
                  {job?.aiSoftSkills?.map((skill, index) => (
                    <Pill key={index} isSmall={true} color={Colors.Contrast} isBold={true}>
                      {skill}
                    </Pill>
                  ))}
                </PillsList>
                {!!job?.aiNonMatchingRequiredSkills?.length && job?.aiNonMatchingRequiredSkills?.length > 0 && (
                  <>
                    <Copy marginBottom={0}>Recommendations for required skills not met</Copy>
                    <Copy styleLevel={3}>
                      The following required skills are required for the job, but they are either absent or not
                      highlighted in your resume. You may want to consider adding them to enhance your resume.
                    </Copy>
                    <PillsList>
                      {job?.aiNonMatchingRequiredSkills?.map((skill, index) => (
                        <Pill key={index} isSmall={true} color={Colors.Contrast} isBold={true}>
                          {skill}
                        </Pill>
                      ))}
                    </PillsList>
                  </>
                )}
                {!!job?.aiNonMatchingNiceToHaveSkills?.length && job?.aiNonMatchingNiceToHaveSkills?.length > 0 && (
                  <>
                    <Copy marginBottom={0}>Recommendations for nice-to-have skills not met</Copy>
                    <Copy styleLevel={3}>
                      The following nice-to-have skills are listed for the job, but they are either absent or not
                      highlighted in your resume. You may want to consider adding them to enhance your resume.
                    </Copy>
                    <PillsList>
                      {job?.aiNonMatchingNiceToHaveSkills?.map((skill, index) => (
                        <Pill key={index} isSmall={true} color={Colors.Contrast} isBold={true}>
                          {skill}
                        </Pill>
                      ))}
                    </PillsList>
                  </>
                )}
              </HeaderRightWrapper>
            </HeaderWrapper>
          )}
        </>
      )} */}

      <BodyWrapper>
        <Label>Job Description</Label>
        {job?.jobDescription ? (
          <RichTextInputReadOnly>{job?.jobDescription}</RichTextInputReadOnly>
        ) : (
          <Copy styleLevel={3}>Job Description not provided.</Copy>
        )}
        <Label>Job Requirements</Label>
        {job?.jobRequirement ? (
          <RichTextInputReadOnly>{job?.jobRequirement}</RichTextInputReadOnly>
        ) : (
          <Copy styleLevel={3}>Job Requirement not provided.</Copy>
        )}
        <Label>Company</Label>
        {job?.companyInfo ? (
          <RichTextInputReadOnly>{job?.companyInfo}</RichTextInputReadOnly>
        ) : (
          <Copy styleLevel={3}>Company Information not provided.</Copy>
        )}
      </BodyWrapper>
    </Container>
  );
};

// React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
`;

const HeaderLeftWrapper = styled.div`
  display: block;
  position: relative;
`;

const HeaderRightWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const BodyWrapper = styled.div``;

const Label = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: ${Colors.Grey};
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
