import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { GenerateAIEmailProps } from './GenerateAIEmail.types';

export const Container = styled.div`
  ${media.from_tablet`
    width: 800px;
  `}
  ${media.from_laptop`
    width: 900px;
  `}
  ${media.from_large_laptop`
    width: 1000px;
  `}
  height: 100%;
  /* padding: 16px 8px; */
`;

export const CenteredLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
