import { useCallback, useReducer } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Descendant } from 'slate';
import set from 'lodash/set';

export const initialState: AIEmailStoreState = {
  isLoading: true,
  isDirty: 0,
  isSaving: false,
  uuid: undefined,
  contactEmail: '',
  contactName: '',
  category: '',
  subject: '',
  body: [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ],
};

export function reducer(state: AIEmailStoreState, { type, payload }: Action): AIEmailStoreState {
  switch (type) {
    case 'CHANGE_FORM_VALUE': {
      try {
        const updatedState = JSON.parse(JSON.stringify(state));
        const { key, value } = payload;
        set(updatedState, key, value);
        return {
          ...updatedState,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'INITIALIZE_CONTENT': {
      try {
        console.log('INITIALIZE_CONTENT', payload);
        const updatedState = JSON.parse(JSON.stringify(payload));
        return {
          ...state,
          ...updatedState,
          isLoading: false,
          isDirty: 1,
          isSaving: false,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_UUID': {
      try {
        return {
          ...state,
          uuid: payload,
          isDirty: 0,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_IS_SAVING': {
      try {
        return {
          ...state,
          isSaving: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_IS_DIRTY': {
      try {
        return {
          ...state,
          isDirty: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_IS_LOADING': {
      try {
        return {
          ...state,
          isLoading: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'UPDATE_SUBJECT': {
      try {
        return {
          ...state,
          subject: payload,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'UPDATE_BODY': {
      try {
        return {
          ...state,
          body: payload,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    default:
      return state;
  }
}

export const usePersistReducer = (LOCAL_STORAGE_KEY: string, INITIAL_STATE: AIEmailStoreState) => {
  // grab saved value from `localStorage` and
  // a function to update it. if
  // no value is retrieved, use `INITIAL_STATE`
  const [savedState, saveState] = useLocalStorage(LOCAL_STORAGE_KEY, INITIAL_STATE);

  // wrap `reducer` with a memoized function that
  // syncs the `newState` to `localStorage` before
  // returning `newState`. memoizing is important!
  const reducerLocalStorage = useCallback(
    (state: AIEmailStoreState, action: Action) => {
      const newState = reducer(state, action);

      saveState(newState);

      return newState;
    },
    [saveState]
  );

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, INITIAL_STATE);
};

export interface AIEmailStoreState {
  isLoading: boolean;
  isDirty: number;
  isSaving: boolean;
  uuid?: string;
  category: string;
  subject: string;
  contactEmail?: string;
  contactName?: string;
  body: Descendant[];
}

interface Action {
  type: ActionType;
  payload: any;
}

export type ActionType =
  | 'CHANGE_FORM_VALUE'
  | 'SET_IS_LOADING'
  | 'SET_UUID'
  | 'SET_IS_DIRTY'
  | 'SET_IS_SAVING'
  | 'INITIALIZE_CONTENT'
  | 'UPDATE_SUBJECT'
  | 'UPDATE_BODY';
