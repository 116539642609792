import styled from 'styled-components';

import { AICoverLetterListItemProps } from './AICoverLetterListItem.types';

import { formatDate } from '../../../utils/date';
import { Colors } from '../../../styles/colors';
import { generatePdf } from '../../../utils/pdfMake';
import { serializeSlateNodeToHtml } from '../SlateEditor/helper';

import { Copy } from '../../atoms/Typography';
import { Container, ContainerLeft, ContainerRight, LineWrapper } from './AICoverLetterListItem.styles';
import { IconButton } from '../../atoms/Icons/Icon';
import { AttachmentIcon, DeleteIcon, DownloadIcon, EditIcon } from '../../atoms/Icons';

export const AICoverLetterListItem = ({
  coverLetter,
  onEditClick,
  onDeleteClick,
  handleDownload,
}: React.PropsWithChildren<AICoverLetterListItemProps>) => {
  const { createdAt, updatedAt, uuid, state: { coverLetter: { title = '', content = '' } = {} } = {} } = coverLetter;

  return (
    <Container>
      <ContainerLeft>
        <LineWrapper>
          <AttachmentIcon size={3} />
          <div>
            <Copy isBold={true}>Cover Letter</Copy>
            <Copy styleLevel={2}>{title}</Copy>
          </div>
        </LineWrapper>
        <Copy styleLevel={3} color={Colors.GreyDarkest}>
          Last modified on{' '}
          {formatDate({
            date: updatedAt ? updatedAt : createdAt,
            format: 'shortdatetime',
          })}
        </Copy>
      </ContainerLeft>
      <ContainerRight>
        <IconButton onClick={() => onEditClick(coverLetter)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDownload(coverLetter)}>
          <DownloadIcon />
        </IconButton>
        {/* <IconButton
          onClick={() => {
            if (!coverLetter) return;
            const serializedBody = serializeSlateNodeToHtml(content);
            const html = `
          <html>
            <head>
              <style>
                body {
                  font-family: Arial, Helvetica, sans-serif;
                }
              </style>
            </head>
            <body>
              ${serializedBody}
            </body>
          </html>
        `;

            generatePdf(html, `cover-letter.pdf`);
          }}
        >
          <DownloadIcon />
        </IconButton> */}
        <IconButton>
          <DeleteIcon onClick={() => onDeleteClick(coverLetter)} />
        </IconButton>
      </ContainerRight>
    </Container>
  );
};

export const AIEmailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
