import styled from 'styled-components';
import { Colors } from '../../../styles/colors';

export const Container = styled.div`
  padding: 16px;
  flex: 1 1 320px;
  max-width: 400px;
  position: relative;

  // this is important to make the paper 100% height
  > div {
    height: 100%;
    padding: 16px 8px 8px 8px;
  }
`;

export const ContainerSkeleton = styled.div`
  padding: 16px;
  flex: 1 1 320px;
  max-width: 400px;
  position: relative;
  cursor: pointer;

  // this is important to make the paper 100% height
  > div {
    height: 100%;
    /* border: 1px solid red; */
    padding: 16px 8px 8px 8px;
  }
`;

export const Actions = styled.div`
  position: absolute;
  display: flex;
  gap: 0px;
  top: 16px;
  right: 8px;
  padding: 8px;
  cursor: pointer;
`;

export const TwoColums = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  gap: 8px;
`;

export const FirstColumn = styled.div`
  /* border: 1px solid red; */
  flex: 1 1 40px;
`;

export const SecondColumn = styled.div`
  /* border: 1px solid red; */
  flex: 1 1 50%;
`;

export const LineWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  gap: 4px;
`;

export const EventFormat = styled.div`
  font-weight: bold;
`;

export const TimeFormat = styled.div`
  font-weight: bold;
  color: ${Colors.PrimaryDarkest};
`;

export const TimeFormatToday = styled.div`
  font-weight: bold;
  color: ${Colors.ContrastDarkest};
`;

export const ContainerList = styled.div`
  border: 1px dotted ${Colors.Contrast};
  padding: 0 0 16px 0px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
