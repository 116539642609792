import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationTailorResumeArgs } from '../../generated/types';

export const TAILOR_RESUME = gql`
  mutation TailorResume($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $resumeUuid: String!) {
    tailorResume(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, resumeUuid: $resumeUuid) {
      uuid
    }
  }
`;

export const useTailorResume = (
  options?: MutationHookOptions<Pick<Mutation, 'tailorResume'>, MutationTailorResumeArgs>
) =>
  useMutation<Pick<Mutation, 'tailorResume'>, MutationTailorResumeArgs>(TAILOR_RESUME, {
    ...options,
  });
