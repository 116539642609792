import styled from 'styled-components';

import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.div<
  Partial<{
    color: string;
    $isDisabled: boolean;
  }>
>`
  ${({ color = Colors.Primary }) =>
    color &&
    `
      border: 3px solid ${color};
    `}

  border-radius: 10px;
  max-width: 100px;
`;

export const Day = styled.div<
  Partial<{
    color: string;
    $isDisabled: boolean;
  }>
>`
  ${({ color = Colors.Primary }) =>
    color &&
    `
    background-color: ${color};
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    color: ${Colors.GreyLighter};
    `}

  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  padding: 5px 0;

  ${media.from_tablet`
    font-size: 2rem;
  `}
`;

export const Month = styled.div<
  Partial<{
    color: string;
    $isDisabled: boolean;
  }>
>`
  font-size: 1.1rem;
  text-align: center;

  ${({ $isDisabled }) =>
    $isDisabled &&
    `
    color: ${Colors.GreyLighter};
    `}

  ${media.from_tablet`
    font-size: 1.2rem;
  `}
`;
