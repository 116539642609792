import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveEventArgs } from '../../generated/types';

export const ARCHIVE_EVENT = gql`
  mutation ArchiveEvent(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $eventUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveEvent(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      eventUuid: $eventUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveEvent = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveEvent'>, MutationArchiveEventArgs>
) =>
  useMutation<Pick<Mutation, 'archiveEvent'>, MutationArchiveEventArgs>(ARCHIVE_EVENT, {
    update(cache, { data: { archiveEvent = {} } = {} }) {
      if (!archiveEvent.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Event',
          uuid: archiveEvent.uuid,
        }),
        fields: {
          isDeleted() {
            return archiveEvent.isDeleted;
          },
        },
      });
    },
    ...options,
  });
