import { EventProps } from './Event.types';
import { Link } from 'react-router-dom';

import { Colors } from '../../../styles/colors';
import { convertEnumToString } from '../../../utils/enum';

import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Paper } from '../../atoms/Paper';
import { CalendarDay } from '../../atoms/CalendarDay';
import { Copy } from '../../atoms/Typography';
import { AddIcon, DeleteIcon, EditIcon, ExternalLinkIcon, LocationIcon } from '../../atoms/Icons';
import { Actions, Container, ContainerList, ContainerSkeleton, EventFormat, FirstColumn, LineWrapper, SecondColumn, TimeFormat, TimeFormatToday, TwoColums } from './Event.styles';
import { IconButton } from '../../atoms/Icons/Icon';

export const Event: React.FC<any> = ({ event, onEditClick, onDeleteClick }: React.PropsWithChildren<EventProps>) => {
  const { startAt, endAt, description, meetingUrl, type, location, locationCity, locationUrl } = event;
  const todaydate = new Date().toISOString().slice(0, 10);
  const startAtDate = new Date(startAt).toISOString().slice(0, 10);
  const startAtMonth = new Date(startAt).toLocaleDateString('en-UK', { month: 'short' });
  const startAtDay = new Date(startAt).toLocaleDateString('en-UK', { day: 'numeric' });
  const startAtHourMinute = new Date(startAt).toLocaleTimeString('en-UK', { hour: 'numeric', minute: 'numeric' });
  const endAtHour = new Date(endAt).toLocaleTimeString('en-UK', { hour: 'numeric', minute: 'numeric' });
  const isToday = todaydate === startAtDate;
  const isOld = todaydate > startAtDate;
  const isEditable = !!onEditClick;
  const isDeletable = !!onDeleteClick;

  return (
    <ErrorBoundary message="Molecules\Event">
      <Container>
        <Paper elevation={1}>
          {/* <pre>{JSON.stringify(event, null, 2)}</pre> */}
          <Actions>
            {isDeletable && (
              <IconButton>
                <DeleteIcon size={1.2} onClick={onDeleteClick} />
              </IconButton>
            )}
            {isEditable && (
              <IconButton>
                <EditIcon size={1.2} onClick={onEditClick} />
              </IconButton>
            )}
          </Actions>

          <TwoColums>
            <FirstColumn>
              <CalendarDay
                day={startAtDay}
                month={startAtMonth}
                color={isToday ? Colors.Contrast : isOld ? Colors.Grey : undefined}
              />
            </FirstColumn>
            <SecondColumn>
              <LineWrapper>
                <EventFormat>{convertEnumToString('EventFormat', type)}</EventFormat>
              </LineWrapper>
              <LineWrapper>
                From
                {isToday ? (
                  <>
                    <TimeFormatToday>{startAtHourMinute}</TimeFormatToday> to{' '}
                    <TimeFormatToday>{endAtHour}</TimeFormatToday>
                  </>
                ) : (
                  <>
                    <TimeFormat>{startAtHourMinute}</TimeFormat> to <TimeFormat>{endAtHour}</TimeFormat>
                  </>
                )}
              </LineWrapper>
              <Copy styleLevel={3} marginBottom={8}>
                {description}
              </Copy>
              <TwoColums>
                {meetingUrl && (
                  <LineWrapper>
                    Meeting URL
                    {meetingUrl && (
                      <Link target="_blank" to={meetingUrl}>
                        <ExternalLinkIcon color={Colors.PrimaryDarkest} />
                      </Link>
                    )}
                  </LineWrapper>
                )}
                {location && locationCity && (
                  <LineWrapper>
                    <LocationIcon />
                    {locationCity}
                    {locationUrl && (
                      <Link target="_blank" to={locationUrl}>
                        <ExternalLinkIcon color={Colors.PrimaryDarkest} />
                      </Link>
                    )}
                  </LineWrapper>
                )}
              </TwoColums>
            </SecondColumn>
          </TwoColums>
        </Paper>
      </Container>
    </ErrorBoundary>
  );
};

export const EventSkeleton: React.FC<any> = ({ onClick }: React.PropsWithChildren<any>) => {
  return (
    <ErrorBoundary message="Molecules\Event">
      <ContainerSkeleton onClick={onClick}>
        <Paper elevation={1}>
          <Actions>
            <IconButton>
              <AddIcon size={1.2} onClick={onClick} />
            </IconButton>
          </Actions>
          <TwoColums>
            <FirstColumn>
              <CalendarDay day={'?'} month={'?'} color={Colors.GreyLightest} isDisabled={true} />
            </FirstColumn>
            <SecondColumn>
              <LineWrapper>
                <b>Add a new event</b>
              </LineWrapper>
              <LineWrapper></LineWrapper>
              <Copy styleLevel={3} marginBottom={8}>
                Track your events
              </Copy>
              <TwoColums></TwoColums>
            </SecondColumn>
          </TwoColums>
        </Paper>
      </ContainerSkeleton>
    </ErrorBoundary>
  );
};

export const EventsList: React.FC<any> = ({ children }: React.PropsWithChildren<EventProps>) => {
  return (
    <ErrorBoundary message="Molecules\EventsList">
      <ContainerList>{children}</ContainerList>
    </ErrorBoundary>
  );
};

