import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationRenderCoverLetterArgs } from '../../generated/types';

export const RENDER_COVER_LETTER = gql`
  mutation RenderCoverLetter(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $coverLetterUuid: String
    $state: JSON!
  ) {
    renderCoverLetter(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      coverLetterUuid: $coverLetterUuid
      state: $state
    )
  }
`;

export const useRenderCoverLetter = (
  options?: MutationHookOptions<Pick<Mutation, 'renderCoverLetter'>, MutationRenderCoverLetterArgs>
) =>
  useMutation<Pick<Mutation, 'renderCoverLetter'>, MutationRenderCoverLetterArgs>(RENDER_COVER_LETTER, {
    ...options,
  });
