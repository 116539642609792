import { useCallback, useReducer } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Descendant } from 'slate';
import set from 'lodash/set';

import {
  DesignColorPalette as DesignColorPaletteEnum,
  DesignFontFamily as DesignFontFamilyEnum,
  DesignDensity,
  DesignTemplateName,
} from '../../../../generated/types';

export const initialState: CoverLetterStoreState = {
  isLoading: true,
  isDirty: 0,
  isSaving: false,
  uuid: undefined,
  design: {
    colorPalette: DesignColorPaletteEnum.Default,
    fontFamily: DesignFontFamilyEnum.Times,
    density: DesignDensity.Normal,
    templateName: DesignTemplateName.Aries,
    hasLastModifiedDate: false,
    hasParagraphIndendation: false,
    paragraphTextAlignment: 'justify',
  },
};

export function reducer(state: CoverLetterStoreState, { type, payload }: Action): CoverLetterStoreState {
  const { coverLetter, design } = state;

  switch (type) {
    case 'CHANGE_FORM_VALUE': {
      try {
        const updatedState = JSON.parse(JSON.stringify(state));
        const coverLetter = updatedState.coverLetter;
        const { key, value } = payload;
        set(coverLetter, key, value);
        return {
          ...updatedState,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'INITIALIZE_CONTENT': {
      try {
        const updatedState = JSON.parse(JSON.stringify(payload));
        return {
          ...state,
          coverLetter: {
            ...coverLetter,
            content: updatedState.coverLetter.content,
            title: updatedState.coverLetter.title,
          },
          isLoading: false,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_UUID': {
      try {
        return {
          ...state,
          coverLetter: {
            ...coverLetter,
          },
          uuid: payload,
          isDirty: 0,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_IS_SAVING': {
      try {
        return {
          ...state,
          isSaving: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_IS_DIRTY': {
      try {
        return {
          ...state,
          isDirty: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'SET_IS_LOADING': {
      try {
        return {
          ...state,
          isLoading: payload,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'CHANGE_DESIGN_VALUE': {
      try {
        const { key, value } = payload;
        const updatedDesign = {
          ...design,
        };
        set(updatedDesign, key, value);
        return {
          ...state,
          design: updatedDesign,
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'UPDATE_TITLE': {
      try {
        return {
          ...state,
          coverLetter: {
            ...coverLetter,
            title: payload,
          },
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    case 'UPDATE_CONTENT': {
      try {
        return {
          ...state,
          coverLetter: {
            ...coverLetter,
            content: payload,
          },
          isDirty: state.isDirty + 1,
        };
      } catch (error) {
        console.error(error);
        return state;
      }
    }

    default:
      return state;
  }
}

export const usePersistReducer = (LOCAL_STORAGE_KEY: string, INITIAL_STATE: CoverLetterStoreState) => {
  // grab saved value from `localStorage` and
  // a function to update it. if
  // no value is retrieved, use `INITIAL_STATE`
  const [savedState, saveState] = useLocalStorage(LOCAL_STORAGE_KEY, INITIAL_STATE);

  // wrap `reducer` with a memoized function that
  // syncs the `newState` to `localStorage` before
  // returning `newState`. memoizing is important!
  const reducerLocalStorage = useCallback(
    (state: CoverLetterStoreState, action: Action) => {
      const newState = reducer(state, action);

      saveState(newState);

      return newState;
    },
    [saveState]
  );

  // use wrapped reducer and the saved value from
  // `localStorage` as params to `useReducer`.
  // this will return `[state, dispatch]`
  return useReducer(reducerLocalStorage, INITIAL_STATE);
};

export interface CoverLetterStoreState {
  isLoading: boolean;
  isDirty: number;
  isSaving: boolean;
  uuid?: string;
  coverLetter?: CoverLetterStore;
  design?: DesignStore;
}

export interface CoverLetterStore {
  title: string;
  content: Descendant[];
  basics?: {
    name?: string;
    jobTitle: string;
  };
  contacts?: {
    email?: string;
    phone?: string;
    linkedin?: string;
    city?: string;
    country?: string;
  };
  company?: {
    name?: string;
    country?: string;
  };
}

export interface DesignStore {
  colorPalette: DesignColorPaletteEnum;
  fontFamily: DesignFontFamilyEnum;
  density: DesignDensity;
  templateName: DesignTemplateName;
  hasLastModifiedDate?: boolean;
  hasParagraphIndendation?: boolean;
  paragraphTextAlignment?: 'left' | 'justify' | 'right';
}

interface Action {
  type: ActionType;
  payload: any;
}

export type ActionType =
  | 'SET_IS_LOADING'
  | 'SET_UUID'
  | 'SET_IS_DIRTY'
  | 'SET_IS_SAVING'
  | 'INITIALIZE_CONTENT'
  | 'CHANGE_DESIGN_VALUE'
  | 'CHANGE_FORM_VALUE'
  | 'UPDATE_TITLE'
  | 'UPDATE_CONTENT';
