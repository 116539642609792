import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveCoverLetterArgs } from '../../generated/types';

export const ARCHIVE_COVER_LETTER = gql`
  mutation ArchiveCoverLetter(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $coverLetterUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveCoverLetter(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      coverLetterUuid: $coverLetterUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveCoverLetter = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveCoverLetter'>, MutationArchiveCoverLetterArgs>
) =>
  useMutation<Pick<Mutation, 'archiveCoverLetter'>, MutationArchiveCoverLetterArgs>(ARCHIVE_COVER_LETTER, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const archiveCoverLetter = data?.archiveCoverLetter;
      const archiveCoverLetterUuid = archiveCoverLetter?.uuid;

      if (!jobUuid) return;
      if (!archiveCoverLetter) return;
      if (!archiveCoverLetterUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          coverLetters(existingItem = [], { readField }) {
            return [...existingItem].filter((taskRef) => {
              const uuid = readField('uuid', taskRef);
              return uuid !== archiveCoverLetterUuid;
            });
          },
        },
      });
    },
    ...options,
  });
