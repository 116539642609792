import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveTaskArgs } from '../../generated/types';

export const ARCHIVE_TASK = gql`
  mutation ArchiveTask(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $taskUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveTask(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      taskUuid: $taskUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveTask = (options?: MutationHookOptions<Pick<Mutation, 'archiveTask'>, MutationArchiveTaskArgs>) =>
  useMutation<Pick<Mutation, 'archiveTask'>, MutationArchiveTaskArgs>(ARCHIVE_TASK, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const archiveTask = data?.archiveTask;
      const archiveTaskUuid = archiveTask?.uuid;

      if (!jobUuid) return;
      if (!archiveTask) return;
      if (!archiveTaskUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          tasks(existingTasks = [], { readField }) {
            return [...existingTasks].filter((taskRef) => {
              const taskUuid = readField('uuid', taskRef);
              return taskUuid !== archiveTaskUuid;
            });
          },
        },
      });
    },
    ...options,
  });
