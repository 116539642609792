import { Reorder } from 'framer-motion';
import styled from 'styled-components';

import { Task } from '../../../generated/types';

import { TaskManagerItem } from './TaskManagerItem';
import { TaskManagerItemWithAI } from './TaskManagerItemWithAI';

export const TaskManagerItemsList = ({
  isSmart = false,
  tasks,
  handleCompleteTask,
  handleDeleteTask,
  handleEditTask,
}: {
  isSmart?: boolean;
  tasks: Task[];
  handleCompleteTask: any;
  handleDeleteTask: any;
  handleEditTask: any;
}) => {
  const onReorder = (items: Task[]) => {
    // console.log('onReorder', items);
  };

  return (
    <StyledReorderGroup axis="y" values={tasks} onReorder={onReorder}>
      {tasks.map((task, index) => (
        <StyledReorderItem key={task.uuid} value={task}>
          {isSmart ? (
            <TaskManagerItemWithAI
              index={index}
              task={task}
              handleDeleteTask={handleDeleteTask}
              handleCompleteTask={handleCompleteTask}
              handleEditTask={handleEditTask}
            />
          ) : (
            <TaskManagerItem
              index={index}
              task={task}
              handleDeleteTask={handleDeleteTask}
              handleCompleteTask={handleCompleteTask}
              handleEditTask={handleEditTask}
            />
          )}
        </StyledReorderItem>
      ))}
    </StyledReorderGroup>
  );
};

const StyledReorderGroup = styled(Reorder.Group)`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const StyledReorderItem = styled(Reorder.Item)`
  padding: 0;
`;
