import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationPrepareEmailArgs } from '../../generated/types';

export const PREPARE_AI_EMAIL = gql`
  mutation PrepareEmail(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $connectionId: String
    $taskId: String!
    $category: EmailCategory!
  ) {
    prepareEmail(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      connectionId: $connectionId
      taskId: $taskId
      category: $category
    )
  }
`;

export const usePrepareAIEmail = (
  options?: MutationHookOptions<Pick<Mutation, 'prepareEmail'>, MutationPrepareEmailArgs>
) =>
  useMutation<Pick<Mutation, 'prepareEmail'>, MutationPrepareEmailArgs>(PREPARE_AI_EMAIL, {
    ...options,
  });
