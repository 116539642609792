import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationDownloadAiEmailArgs } from '../../generated/types';

export const DOWNLOAD_AI_EMAIL = gql`
  mutation DownloadAIEmail($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $aiEmailUuid: String!) {
    downloadAIEmail(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, aiEmailUuid: $aiEmailUuid) {
      uuid
      subject
      category
      body
      resource
      temporaryUrl
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const useDownloadAIEmail = (
  options?: MutationHookOptions<Pick<Mutation, 'downloadAIEmail'>, MutationDownloadAiEmailArgs>
) =>
  useMutation<Pick<Mutation, 'downloadAIEmail'>, MutationDownloadAiEmailArgs>(DOWNLOAD_AI_EMAIL, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const updateAIEmail = data?.downloadAIEmail;
      const updateAIEmailUuid = updateAIEmail?.uuid;

      if (!jobUuid) return;
      if (!updateAIEmail) return;
      if (!updateAIEmailUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          aiEmails(existingItems = []) {
            return existingItems.map((item: any) => {
              if (item.uuid === updateAIEmailUuid) {
                return updateAIEmail;
              } else {
                return item;
              }
            });
          },
        },
      });
    },
    ...options,
  });
