import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { AICoverLetterListItemProps } from './AICoverLetterListItem.types';

export const Container = styled.div`
  border: 1px solid ${Colors.PrimaryLightest};
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
