import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationImproveCoverLetterArgs } from '../../generated/types';

export const IMPROVE_COVER_LETTER = gql`
  mutation ImproveCoverLetter($data: ImproveCoverLetterData!, $userUuid: String, $boardUuid: String, $jobUuid: String) {
    improveCoverLetter(data: $data, userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid)
  }
`;

export const useImproveCoverLetter = (
  options?: MutationHookOptions<Pick<Mutation, 'improveCoverLetter'>, MutationImproveCoverLetterArgs>
) =>
  useMutation<Pick<Mutation, 'improveCoverLetter'>, MutationImproveCoverLetterArgs>(IMPROVE_COVER_LETTER, {
    ...options,
  });
