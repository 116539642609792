import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateCoverLetterArgs } from '../../generated/types';

export const CREATE_COVER_LETTER = gql`
  mutation createCoverLetter($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $state: JSON!) {
    createCoverLetter(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, state: $state) {
      uuid
      state
      isDeleted
      createdAt
    }
  }
`;

export const useCreateCoverLetter = (
  options?: MutationHookOptions<Pick<Mutation, 'createCoverLetter'>, MutationCreateCoverLetterArgs>
) =>
  useMutation<Pick<Mutation, 'createCoverLetter'>, MutationCreateCoverLetterArgs>(CREATE_COVER_LETTER, {
    // update(cache, { data = {} }, options) {
    //   if (!data) return;
    //   if (!options) return;
    //   const jobUuid = options?.variables?.jobUuid;
    //   const createCoverLetter = data?.createCoverLetter;
    //   const createCoverLetterUuid = createCoverLetter?.uuid;

    //   if (!jobUuid) return;
    //   if (!createCoverLetter) return;
    //   if (!createCoverLetterUuid) return;

    //   cache.modify({
    //     id: cache.identify({
    //       __typename: 'Job',
    //       uuid: jobUuid,
    //     }),
    //     fields: {
    //       coverLetters(existingItems = []) {
    //         console.log('>>>>>>');
    //         console.log('createCoverLetter', createCoverLetter);
    //         // add new item to the beginning of the list

    //         return [...existingItems, createCoverLetter];

    //         // return [...existingItems, createCoverLetter];
    //       },
    //     },
    //   });
    // },
    ...options,
  });
