import { toast } from 'react-toastify';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Descendant } from 'slate';

import { GenerateAICoverLetterProps } from './GenerateAICoverLetter.types';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import aiInProgressAnimation from '../../../assets/lotties/ai-in-progress.json';
import { Lottie } from '../../atoms/Lottie';
import { Colors } from '../../../styles/colors';
import { usePersistReducer } from './Store';

import { Container, LeftColumnLayout, RightColumnLayout, TwoColumnsLayout } from './GenerateAICoverLetter.styles';
import { CloseIcon, CopyIcon, DownloadIcon, MailIcon, SparklingIcon } from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Copy } from '../../atoms/Typography';
import { SlateEditor } from '../../molecules/SlateEditor';
import { IconButton } from '../../atoms/Icons/Icon';
import { Placeholder } from '../../atoms/Placeholder';
import { serializeSlateNodeToText } from '../../molecules/SlateEditor/helper';
import {
  DesignColorPalette as DesignColorPaletteEnum,
  DesignFontFamily as DesignFontFamilyEnum,
  DesignDensity,
  DesignTemplateName,
  WritingStyle,
} from '../../../generated/types';
import { TabController, TabPanel } from '../../atoms/Tabs';
import { DesignColorPalette } from '../../atoms/DesignColorPalette';
import { DesignFontSize } from '../../atoms/DesignFontSize';
import { DesignFontFamily } from '../../atoms/DesignFontFamily';
import { DesignTemplate } from '../../atoms/DesignTemplate';
import { FunctionalIFrameComponent } from '../../atoms/FunctionalIFrameComponent';
import { ResumeDesignOptionsForm } from '../../molecules/ResumeDesignOptionsForm';
import { Spacer } from '../../atoms/Spacer/Spacer';
import { Form } from '../../atoms/Form/Form';
import { CoverLetterPreview } from '../../molecules/CoverLetterPreview/CoverLetterPreview';
import { ResumeBasicFormSimple } from '../../molecules/ResumeBasicForm/ResumeBasicFormSimple';
import { ResumeContactFormSimple } from '../../molecules/ResumeContactForm/ResumeContactFormSimple';
import { SmallLoader } from '../../atoms/Loader';

const LOCAL_STORAGE_KEY = 'cover-letter';
const SAVING_TIMEOUT = 5000; // 5 seconds

export const GenerateAICoverLetter = ({
  board,
  job,
  user,
  isGeneratingCoverLetter,
  coverLetter: initialCoverLetter,
  handleSave,
  handleImproveCoverLetter,
  handleGenerateCoverLetter,
  handleRenderCoverLetter,
  onCloseClick,
}: React.PropsWithChildren<GenerateAICoverLetterProps>) => {
  const { linkedin, locationCity, locationCountry, title: boardTitle } = board || {};
  const [_copiedText, copy] = useCopyToClipboard();
  const { company, companyLocationCountry, jobTitle } = job || {};
  const { name, email, phone } = user || {};

  const initialState = {
    uuid: initialCoverLetter?.uuid,
    ...(initialCoverLetter?.uuid
      ? {
          ...initialCoverLetter?.state,
        }
      : {
          coverLetter: {
            basics: {
              jobTitle: jobTitle || boardTitle || '',
              name,
            },
            contacts: {
              email,
              phone,
              linkedin: linkedin,
              city: locationCity,
              country: locationCountry,
            },
            company: {
              name: company,
              country: companyLocationCountry,
            },
          },
        }),
    ...(initialCoverLetter?.uuid
      ? {}
      : {
          design: {
            templateName: DesignTemplateName.Aries,
            colorPalette: DesignColorPaletteEnum.Default,
            density: DesignDensity.Normal,
            fontFamily: DesignFontFamilyEnum.Times,
          },
        }),
    isLoading: initialCoverLetter?.uuid ? false : true,
    isDirty: initialCoverLetter?.uuid ? 0 : 1,
    isSaving: false,
  };
  const [state, dispatch] = usePersistReducer(LOCAL_STORAGE_KEY, initialState);

  useEffect(() => {
    if (initialCoverLetter && !isGeneratingCoverLetter && !initialCoverLetter?.uuid) {
      dispatch({
        type: 'INITIALIZE_CONTENT',
        payload: initialCoverLetter.state,
      });
    }
  }, [isGeneratingCoverLetter]);

  useEffect(() => {
    const { isDirty } = state;
    let timeoutId: NodeJS.Timeout;

    if (isDirty) {
      timeoutId = setTimeout(() => {
        handleSaveContent();
        dispatch({
          type: 'SET_IS_DIRTY',
          payload: 0,
        });
      }, SAVING_TIMEOUT);
    }
    // Cleanup function to clear the timeout if the component unmounts or form data changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [state.isDirty]);

  async function onImproveDescription(
    selection: Descendant[],
    writingStyle: WritingStyle
  ): Promise<string[] | undefined> {
    if (!handleImproveCoverLetter) return [];

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        case WritingStyle.Spellcheck:
          count = 1;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveCoverLetter(selection, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return;
    }
  }

  async function onFixTypo(selection: Descendant[]) {
    if (!handleImproveCoverLetter) return;

    try {
      const result = await handleImproveCoverLetter(selection, WritingStyle.Spellcheck, 1);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return;
    }
  }

  async function handleDownloadRichPdf(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    await handleRenderCoverLetter(state);
  }

  // async function handleDownloadPdf(event: React.MouseEvent<HTMLElement, MouseEvent>) {
  //   event.preventDefault();
  //   if (!aiCoverLetter) return;
  //   const serializedBody = serializeSlateNodeToHtml(slateBody || aiCoverLetter?.content);
  //   const html = `
  //         <html>
  //           <head>
  //             <style>
  //               body {
  //                 font-family: Arial, Helvetica, sans-serif;
  //               }
  //             </style>
  //           </head>
  //           <body>
  //             ${serializedBody}
  //           </body>
  //         </html>
  //       `;

  //   generatePdf(html, `cover-letter.pdf`);
  // }

  async function handleEmail(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    handleSaveContent();

    try {
      const subject = 'Cover Letter';
      const content = state?.coverLetter?.content;
      const body = serializeSlateNodeToText(content);
      const email = '';
      let params = subject || body ? '?' : '';
      if (subject) params += `subject=${encodeURIComponent(subject)}`;
      if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

      window.location.href = `mailto:${email}${params}`;
    } catch (error) {
      toast.error('Something went wrong. Please try again.', {
        position: 'top-left',
      });
    }
  }

  async function handleCopyText(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    handleSaveContent();

    try {
      const content = state?.coverLetter?.content;
      const textContent = serializeSlateNodeToText(content);
      copy(textContent);
      toast.success('Cover letter copied to clipboard', {
        position: 'top-left',
      });
    } catch (error) {
      toast.error('Something went wrong. Please try again.', {
        position: 'top-left',
      });
    }
  }

  async function onGenerateCoverLetter(): Promise<void> {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });
    await handleGenerateCoverLetter();
  }

  async function handleSaveContent(): Promise<void> {
    dispatch({
      type: 'SET_IS_SAVING',
      payload: true,
    });
    const uuid = await handleSave(state);
    dispatch({
      type: 'SET_IS_SAVING',
      payload: false,
    });
    dispatch({
      type: 'SET_UUID',
      payload: uuid,
    });
  }

  function handleContentChange(content: Descendant[]) {
    dispatch({
      type: 'UPDATE_CONTENT',
      payload: content,
    });
  }

  const { design, coverLetter, isLoading, isDirty, isSaving } = state || {};
  const { templateName = DesignTemplateName.Aries, colorPalette, density, fontFamily } = design || {};
  const { content = '', title = '', basics = {}, contacts = {} } = coverLetter || {};

  return (
    <Container>
      <TwoColumnsLayout>
        <LeftColumnLayout>
          <Header>
            <HeaderMain>
              <IconButton>
                <CloseIcon size={1.5} onClick={onCloseClick} />
              </IconButton>
              <div>
                <Copy styleLevel={1}>Cover Letter</Copy>
              </div>
            </HeaderMain>
            {isLoading ? (
              <Pill color={Colors.White} fillColor={Colors.Primary}>
                <strong>Brewing...</strong>
              </Pill>
            ) : (
              <Pill color={Colors.Black} onClick={() => onGenerateCoverLetter()}>
                <SparklingIcon color={Colors.Contrast} /> Regenerate AI Cover Letter
              </Pill>
            )}
            {isLoading ? (
              <Pill color={Colors.PrimaryDarkest} fillColor={Colors.ContrastOverlay}>
                <strong>Unsaved</strong>
              </Pill>
            ) : (
              <>
                {isSaving ? (
                  <Pill color={Colors.PrimaryDarkest} fillColor={Colors.ContrastOverlay}>
                    <strong>Saving...</strong>
                  </Pill>
                ) : isDirty ? (
                  <Pill
                    color={Colors.PrimaryDarkest}
                    fillColor={Colors.ContrastOverlay}
                    onClick={() => handleSaveContent()}
                  >
                    <strong>Unsaved</strong>
                  </Pill>
                ) : (
                  <Pill color={Colors.White} fillColor={Colors.Primary}>
                    <strong>Saved</strong>
                  </Pill>
                )}
              </>
            )}
          </Header>
          <TabController
            headers={[
              { id: 'write', label: 'Write' },
              { id: 'design', label: 'Design' },
              { id: 'data', label: 'Data' },
            ]}
            initialActiveId="write"
          >
            <TabPanel $tabId="write">
              <Actions>
                <Action onClick={handleCopyText}>
                  <CopyIcon size={1.5} />
                  Copy Text
                </Action>
                <Action onClick={handleDownloadRichPdf}>
                  <DownloadIcon size={1.4} />
                  Download PDF
                </Action>
                <Action onClick={handleEmail}>
                  <MailIcon size={1.5} />
                  Email
                </Action>
              </Actions>
              <BackgroundEmailTemplate>
                <EmailTemplate>
                  <EmailTempleBody>
                    <InputWrapper>
                      <Input
                        type="text"
                        placeholder="Subject"
                        value={title}
                        onChange={(event) => {
                          dispatch({
                            type: 'UPDATE_TITLE',
                            payload: event.target.value,
                          });
                        }}
                      />
                    </InputWrapper>
                    {isLoading ? (
                      <Placeholder
                        lottie={<Lottie animationData={aiInProgressAnimation} />}
                        title="In progress"
                        description={
                          <>
                            <Copy marginBottom={16}>Our AI is generating the Cover Letter.</Copy>
                            <Copy styleLevel={3}>Hold on tight, it will be ready in a few seconds.</Copy>
                          </>
                        }
                      />
                    ) : (
                      <SlateEditor
                        onImproveDescription={onImproveDescription}
                        onFixTypo={onFixTypo}
                        onChange={handleContentChange}
                        initialValue={content}
                      />
                    )}
                  </EmailTempleBody>
                </EmailTemplate>
              </BackgroundEmailTemplate>
            </TabPanel>
            <TabPanel $tabId="design">
              <Actions>
                <Action onClick={handleDownloadRichPdf}>
                  <DownloadIcon size={1.4} />
                  Download PDF
                </Action>
              </Actions>
              <DesignContainer>
                <TabController
                  headers={[
                    { id: 'templates', label: 'Templates' },
                    { id: 'colors', label: 'colors' },
                    { id: 'fonts', label: 'font' },
                  ]}
                  initialActiveId="templates"
                >
                  <TabPanel $tabId="templates">
                    <DesignTemplate
                      templateName={templateName}
                      documentType="coverletter"
                      onActionClick={(design) => {
                        dispatch({
                          type: 'CHANGE_DESIGN_VALUE',
                          payload: { key: 'templateName', value: design },
                        });
                      }}
                    ></DesignTemplate>
                  </TabPanel>
                  <TabPanel $tabId="colors">
                    <DesignColorPalette
                      colorPalette={colorPalette}
                      onActionClick={(colorPalette) => {
                        dispatch({
                          type: 'CHANGE_DESIGN_VALUE',
                          payload: { key: 'colorPalette', value: colorPalette },
                        });
                      }}
                    />
                  </TabPanel>
                  <TabPanel $tabId="fonts">
                    <Form onSubmit={() => {}}>
                      <DesignSelection>
                        <DesignFontSize
                          density={density}
                          onActionClick={(density) => {
                            dispatch({
                              type: 'CHANGE_DESIGN_VALUE',
                              payload: { key: 'density', value: density },
                            });
                          }}
                        />
                        <DesignFontFamily
                          fontFamily={fontFamily}
                          onActionClick={(fontFamily) => {
                            dispatch({
                              type: 'CHANGE_DESIGN_VALUE',
                              payload: { key: 'fontFamily', value: fontFamily },
                            });
                          }}
                        />
                        <ResumeDesignOptionsForm design={design} dispatch={dispatch} />
                        <Spacer y={16} />
                      </DesignSelection>
                    </Form>
                  </TabPanel>
                </TabController>
              </DesignContainer>
            </TabPanel>
            <TabPanel $tabId="data">
              <Actions>
                <Action onClick={handleDownloadRichPdf}>
                  <DownloadIcon size={1.4} />
                  Download PDF
                </Action>
              </Actions>
              <DesignContainer>
                <Form onSubmit={() => {}}>
                  <ResumeBasicFormSimple basics={basics} dispatch={dispatch} />
                  <Spacer y={32} />
                  <ResumeContactFormSimple contacts={contacts} dispatch={dispatch} />
                  {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
                </Form>
              </DesignContainer>
            </TabPanel>
          </TabController>
        </LeftColumnLayout>
        <RightColumnLayout>
          <FunctionalIFrameComponent title="coverletter">
            {isLoading ? (
              <CenterContainer>
                <SmallLoader />
              </CenterContainer>
            ) : (
              <CoverLetterPreview state={state} />
            )}
          </FunctionalIFrameComponent>
        </RightColumnLayout>
      </TwoColumnsLayout>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 0;
  border-bottom: 1px solid ${Colors.ContrastLightest};
  flex-wrap: wrap;
`;

const HeaderMain = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const BackgroundEmailTemplate = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #ccc;
`;

const EmailTemplate = styled.div`
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  max-width: 900px;
  padding: 16px 8px;
`;

const EmailTempleBody = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 0 0 8px 0;
  /* border-bottom: 1px solid #ccc; */
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  outline: none;
  width: 100%;
  &:focus {
    border-color: #333;
  }
`;

const DesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 8px;
  padding: 16px;
  position: relative;
  border: 1px solid ${Colors.ContrastLightest};
`;

const DesignSelection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px;
  margin: 16px;
  height: 27cm;
`;
