import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationImproveDescriptionArgs } from '../../generated/types';

export const IMPROVE_DESCRIPTION = gql`
  mutation ImproveDescription($data: ImproveDescriptionData!) {
    improveDescription(data: $data)
  }
`;

export const useImproveDescription = (
  options?: MutationHookOptions<Pick<Mutation, 'improveDescription'>, MutationImproveDescriptionArgs>
) =>
  useMutation<Pick<Mutation, 'improveDescription'>, MutationImproveDescriptionArgs>(IMPROVE_DESCRIPTION, {
    ...options,
  });
