import { CallToActionProps } from './CallToAction.types';
import { Container, LeftContainer, RightContainer, Title } from './CallToAction.styles';
import { Pill } from '../../atoms/Pill';
import { Colors } from '../../../styles/colors';
import { SparklingFullIcon } from '../../atoms/Icons/SparklingIcon';

export const CallToAction = ({
  children,
  title,
  actionButton,
  ...otherProps
}: React.PropsWithChildren<CallToActionProps>) => {
  return (
    <Container {...otherProps}>
      <LeftContainer>
        <Title>
          <div>{title}</div>
          <Pill color={Colors.Black} isSmall={true} icon={<SparklingFullIcon color={Colors.White} />}>
            AI-Powered
          </Pill>
        </Title>
        {children}
      </LeftContainer>
      <RightContainer>{actionButton}</RightContainer>
    </Container>
  );
};
