import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateAiEmailArgs } from '../../generated/types';

export const UPDATE_AI_EMAIL = gql`
  mutation UpdateAIEmail(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $aiEmailUuid: String!
    $data: AIEmailInputData!
  ) {
    updateAIEmail(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      aiEmailUuid: $aiEmailUuid
      data: $data
    ) {
      uuid
      subject
      category
      body
      contactEmail
      contactName
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const useUpdateAIEmail = (
  options?: MutationHookOptions<Pick<Mutation, 'updateAIEmail'>, MutationUpdateAiEmailArgs>
) =>
  useMutation<Pick<Mutation, 'updateAIEmail'>, MutationUpdateAiEmailArgs>(UPDATE_AI_EMAIL, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const updateAIEmail = data?.updateAIEmail;
      const updateAIEmailUuid = updateAIEmail?.uuid;

      if (!jobUuid) return;
      if (!updateAIEmail) return;
      if (!updateAIEmailUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          aiEmails(existingItems = []) {
            return existingItems.map((item: any) => {
              if (item.uuid === updateAIEmailUuid) {
                return updateAIEmail;
              } else {
                return item;
              }
            });
          },
        },
      });
    },
    ...options,
  });
