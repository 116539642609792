import { Suspense, lazy, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Drawer } from '@mui/material';
// import useWebSocket from 'react-use-websocket';

import {
  EmailTemplate,
  EntityType,
  Event as EventType,
  Contact as ContactType,
  JobStatus,
  SortDirection,
  User,
  Note as NoteType,
  Task,
  AiEmail,
  CoverLetter,
  EmailCategory,
  WritingStyle,
} from '../../../generated/types';
import { formatUrl } from '../../../utils/url';
import { Urls } from '../../urls';
import { Colors } from '../../../styles/colors';

import { useRouter } from '../../../hooks/useRouter';
import { useUserContext } from '../../../contexts/UserContext';
import { useActiveTab } from '../../../hooks/useActiveTab';
import { usePushContext } from '../../../contexts/PushContext';

import { useGetBoard } from '../../../graph/queries/getBoard';
import { useGetJob, GET_JOB } from '../../../graph/queries/getJob';
import { useArchiveEvent } from '../../../graph/mutations/archiveEvent';
import { useUpdateEvent } from '../../../graph/mutations/updateEvent';
import { useCreateEvent } from '../../../graph/mutations/createEvent';
import { useCreateContact } from '../../../graph/mutations/createContact';
import { useInviteUserOnJob } from '../../../graph/mutations/inviteUserOnJob';
import { useUnfollowJob } from '../../../graph/mutations/unfollowJob';
import { useArchiveJob } from '../../../graph/mutations/archiveJob';
import { useCreateNote } from '../../../graph/mutations/createNote';
import { useArchiveNote } from '../../../graph/mutations/archiveNote';
import { useUpdateNote } from '../../../graph/mutations/updateNote';
import { useUpdateTask } from '../../../graph/mutations/updateTask';
import { useCreateTask } from '../../../graph/mutations/createTask';
import { useArchiveTask } from '../../../graph/mutations/archiveTask';
import { usePublishJob } from '../../../graph/mutations/publishJob';
import { useCompleteTask } from '../../../graph/mutations/completeTask';
import { useImproveDescription } from '../../../graph/mutations/improveDescription';
import { useFixTypo } from '../../../graph/mutations/fixTypo';
import { usePrepareAIEmail } from '../../../graph/mutations/prepareAIEmail';
import { usePrepareAICoverLetter } from '../../../graph/mutations/prepareAICoverLetter';
import { useUpdateAIEmail } from '../../../graph/mutations/updateAIEmail';
import { useArchiveAIEmail } from '../../../graph/mutations/archiveAIEmail';
import { useCreateCoverLetter } from '../../../graph/mutations/createCoverLetter';
import { useArchiveCoverLetter } from '../../../graph/mutations/archiveCoverLetter';
import { useDownloadAIEmail } from '../../../graph/mutations/downloadAIEmail';
import { useUpdateContact } from '../../../graph/mutations/updateContact';
import { useArchiveContact } from '../../../graph/mutations/archiveContact';
import { useRenderCoverLetter } from '../../../graph/mutations/renderCoverLetter';
import { useRenderResume } from '../../../graph/mutations/renderResume';
import { useTailorResume } from '../../../graph/mutations/tailorResume';
import { GET_JOBS } from '../../../graph/queries/getJobs';

import {
  ViewWrapper,
  JobIntroWrapper,
  PillsWrapper,
  SharedLineWrapper,
  SectionTitle,
  SectionValue,
  SectionTitleInfo,
  DrawerWrapper,
  TwoColumnWrapper,
  TwoColumnLeft,
  TwoColumnRight,
} from './View.style';
import { GenericErrorAlert, InfoAlert } from '../../../components/atoms/InfoAlert';
import { SkeletonBlock } from '../../../components/atoms/Skeleton';
import { PaperCollapse } from '../../../components/molecules/PaperCollapse';
import { Sections, Section } from '../../../components/atoms/Section';
import { PrimaryButton } from '../../../components/atoms/Button';
import { AddIcon, MailSendIcon, EditIcon, DeleteIcon, InfoIcon, CrownIcon } from '../../../components/atoms/Icons';
import { Pill } from '../../../components/atoms/Pill';
import { Modal } from '../../../components/atoms/Modal/Modal';
import { Contact, ContactsList } from '../../../components/molecules/Contact';
import { Breadcrumb, Breadcrumbs } from '../../../components/atoms/Breadcrumbs';
import { JobDescription } from '../../../components/organisms/JobDescription';
import { Event, EventsList } from '../../../components/molecules/Event';
import { useUpdateJob } from '../../../graph/mutations/updateJob';
import { useUpdateJobStatus } from '../../../graph/mutations/updateJobStatus';
import { CloseJobModal } from '../../../components/organisms/CloseJobModal';
import { JobPermissions } from '../../../components/molecules/Permissions/Permissions';
import { Copy } from '../../../components/atoms/Typography';
import { ErrorBoundary } from '../../../components/molecules/ErrorBoundary';
import { SharedLink } from '../../../components/molecules/SharedLink';
import { Spacer } from '../../../components/atoms/Spacer/Spacer';
import { Switch } from '../../../components/atoms/Switch';
import { Tooltip } from '../../../components/atoms/Tooltip';
import { IconButton } from '../../../components/atoms/Icons/Icon';
import { TabController, TabPanel } from '../../../components/atoms/Tabs';
import { TaskManager } from '../../../components/molecules/TaskManager';
import { Note, NoteSkeleton, NotesList } from '../../../components/molecules/Note/Note';
import { SmallLoader } from '../../../components/atoms/Loader';
import { ProcessedFile } from '../../../components/molecules/ProcessedFile/ProcessedFile';
import { JobDescriptionStatistic } from '../../../components/organisms/JobDescriptionStatistic';
import { CallToAction } from '../../../components/molecules/CallToAction';
import { useCreateAITask } from '../../../graph/mutations/createAITask';
import { EventSkeleton } from '../../../components/molecules/Event/Event';
import { GenerateAIEmail } from '../../../components/organisms/GenerateAIEmail';
import { useCreateAIEmail } from '../../../graph/mutations/createAIEmail';
import { AIEmailListItem } from '../../../components/molecules/AIEmailListItem';
import { AIEmailList } from '../../../components/molecules/AIEmailListItem/AIEmailListItem';
import { GenerateAICoverLetter } from '../../../components/organisms/GenerateAICoverLetter';
import { AICoverLetterListItem } from '../../../components/molecules/AICoverLetterListItem';
import { useUpdateCoverLetter } from '../../../graph/mutations/updateCoverLetter';
import { convertToSlate } from '../../../components/molecules/SlateEditor/helper';
import { SparklingFullIcon } from '../../../components/atoms/Icons/SparklingIcon';
import { ProcessedFiles } from '../../../components/molecules/ProcessedFile/ProcessedFile.styles';
import { ProcessedResume } from '../../../components/molecules/ProcessedResume/ProcessedResume';
import { useImproveCoverLetter } from '../../../graph/mutations/improveCoverLetter';
import { useArchiveResume } from '../../../graph/mutations/archiveResume';
import { Resume } from '../../../generated/gql/graphql';

const AcceptJobModal = lazy(() => import('../../../components/organisms/AcceptJobModal'));
const OfferJobModal = lazy(() => import('../../../components/organisms/OfferJobModal'));
const InterviewJobModal = lazy(() => import('../../../components/organisms/InterviewJobModal'));
const ApplyJobModal = lazy(() => import('../../../components/organisms/ApplyJobModal'));
const AddEventModal = lazy(() => import('../../../components/organisms/AddEventModal'));
const AddNoteModal = lazy(() => import('../../../components/organisms/AddNoteModal'));
const AddContactModal = lazy(() => import('../../../components/organisms/AddContactModal'));
const ConfirmDeleteModal = lazy(() => import('../../../components/organisms/ConfirmDeleteModal'));
const InviteUserOnJobModal = lazy(() => import('../../../components/organisms/InviteUserOnJobModal'));

// const SOCKET_URL = 'wss://xnaxdobyei.execute-api.eu-west-1.amazonaws.com/dev/';

export const JobView = () => {
  const [activeTab, setActiveTab] = useActiveTab('job');
  const [drawerVisibleType, setDrawerVisibleType] = useState<'' | 'generate_ai_email' | 'generate_ai_cover_letter'>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isGeneratingCoverLetter, setIsGeneratingCoverLetter] = useState<boolean>(false);
  const [isGeneratingAiEmail, setIsGeneratingAIEmail] = useState<boolean>(false);
  const [modalVisibleType, setModalVisibleType] = useState<
    | ''
    | 'invite_job'
    | 'add_contact'
    | 'add_event'
    | 'add_note'
    | 'edit_event'
    | 'edit_note'
    | 'edit_contact'
    | 'confirm_remove_follower'
    | 'apply_job'
    | 'interview_job'
    | 'offer_job'
    | 'accept_job'
    | 'close_job'
    | 'delete_resume'
    | 'delete_ai_email'
    | 'delete_ai_cover_letter'
    | 'delete_contact'
    | 'delete_event'
    | 'delete_job'
    | 'delete_note'
  >('');

  const [note, setNote] = useState<NoteType | null>(null);
  const [resume, setResume] = useState<ProcessedFile | null>(null);
  const [event, setEvent] = useState<EventType | null>(null);
  const [contact, setContact] = useState<ContactType | undefined>(undefined);
  const [follower, setFollower] = useState<User | null>(null);
  const [aiEmail, setAIEmail] = useState<Partial<AiEmail> | null>(null);
  const [coverLetter, setCoverLetter] = useState<Partial<CoverLetter> | null>(null);
  const { navigate, query: { userUuid = '', boardUuid = '', jobUuid = '' } = {} } = useRouter();
  const { data: { board } = {} } = useGetBoard({
    variables: { userUuid, boardUuid },
  });
  const {
    data: { job } = {},
    loading,
    refetch: jobRefetch,
  } = useGetJob({
    variables: { userUuid, boardUuid, jobUuid },
    fetchPolicy: 'cache-first',
  });

  const [createEvent] = useCreateEvent();
  const [createContact] = useCreateContact();
  const [createNote] = useCreateNote();
  const [createTask] = useCreateTask();
  const [createAiTask] = useCreateAITask();
  const [updateEvent] = useUpdateEvent();
  const [updateJob] = useUpdateJob();
  const [updateJobStatus] = useUpdateJobStatus();
  const [updateNote] = useUpdateNote();
  const [updateContact] = useUpdateContact();
  const [updateTask] = useUpdateTask();
  const [archiveContact] = useArchiveContact();
  const [archiveEvent] = useArchiveEvent();
  const [archiveJob] = useArchiveJob();
  const [archiveNote] = useArchiveNote();
  const [archiveTask] = useArchiveTask();
  const [archiveAIEmail] = useArchiveAIEmail();
  const [archiveCoverLetter] = useArchiveCoverLetter();
  const [archiveResume] = useArchiveResume();
  const [completeTask] = useCompleteTask();
  const [inviteUserOnJob] = useInviteUserOnJob();
  const [unfollowJob] = useUnfollowJob();
  const [publishJob] = usePublishJob();
  const [prepareAICoverLetter] = usePrepareAICoverLetter();
  const [renderCoverLetter] = useRenderCoverLetter();
  const [prepareAIEmail] = usePrepareAIEmail();
  const [createAIEmail] = useCreateAIEmail();
  const [createCoverLetter] = useCreateCoverLetter();
  const [updateAIEmail] = useUpdateAIEmail();
  const [updateCoverLetter] = useUpdateCoverLetter();
  const [downloadAIEmail] = useDownloadAIEmail();
  const [improveDescription] = useImproveDescription();
  const [improveCoverLetter] = useImproveCoverLetter();
  const [renderResume] = useRenderResume();
  const [tailorResume] = useTailorResume();
  const [fixTypo] = useFixTypo();
  const user = useUserContext();

  // const { sendJsonMessage } = useWebSocket(SOCKET_URL, {
  //   onOpen: () => {
  //     // On local dev we need to initial to get the connectionId
  //     if (process.env.NODE_ENV === 'development') {
  //       sendJsonMessage({});
  //     }
  //   },
  //   onMessage: async (event) => {
  //     try {
  //       const parsedData = JSON.parse(event.data);
  //       if (parsedData.connectionId) {
  //         setConnectionId(parsedData.connectionId);
  //       }
  //       if (parsedData.task_type === 'PrepareCoverLetter') {
  //         if (parsedData.task_id === taskId) {
  //           // Refetch the job
  //           const { data: { job } = {} } = await getLazyJob({
  //             variables: { userUuid, boardUuid, jobUuid },
  //             fetchPolicy: 'network-only',
  //           });

  //           setActiveTab('cover_letters');
  //           if (taskId) {
  //             toast.dismiss(taskId);
  //           }
  //         }
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       setConnectionId(null);
  //     }
  //   },
  //   onError: (event) => {
  //     console.error(event);
  //     setConnectionId(null);
  //   },
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: (closeEvent) => false,
  // });
  // const [isLoading, setIsLoading] = useState<'loading' | 'error' | 'idle' | 'completed'>('idle');
  // const [connectionId, setConnectionId] = useState<string | null>(null);
  // const [taskId, setTaskId] = useState<string | null>(() => generateID());
  const { refetchQueries, pushState } = usePushContext();

  useEffect(() => {
    if (!pushState) return;
    const { type } = pushState;
    switch (type) {
      case 'prepareCoverLetterFailed':
        setDrawerVisibleType('');
        break;
      case 'prepareEmailFailed':
        setDrawerVisibleType('');
        break;
      case 'prepareEmailCompleted':
        const { aiEmail: { subject = '', body = '', category = '', contactEmail = '', contactName = '' } = {} } =
          pushState;
        setIsGeneratingAIEmail(false);
        setAIEmail({
          uuid: aiEmail?.uuid,
          category,
          subject,
          body: convertToSlate(body),
          contactEmail,
          contactName,
        });
        break;
      case 'prepareCoverLetterCompleted':
        const { coverLetter: { content = '', title = '' } = {} } = pushState;
        setIsGeneratingCoverLetter(false);
        setCoverLetter({
          state: {
            coverLetter: {
              title,
              content: convertToSlate(content),
            },
          },
        });
        break;
      default:
        break;
    }
  }, [pushState]);

  useEffect(() => {
    if (refetchQueries?.board_view === 'QUERY_JOB' || refetchQueries?.taskId) {
      jobRefetch();
      setIsGeneratingCoverLetter(false);
    }
  }, [refetchQueries?.board_view, refetchQueries?.taskId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!board || !job || !user) {
    return null;
  }

  const canEditJob = job?.permissions?.includes('EDIT_JOB') || false;
  const canInviteJob = job?.permissions?.includes('INVITE_USER') || false;
  const canArchiveJob = job?.permissions?.includes('ARCHIVE_JOB') || false;
  const canShareJob = job?.permissions?.includes('SHARE_JOB') || false;
  const canAddEvent = job?.permissions?.includes('ADD_EVENT') || false;
  const canAddNote = job?.permissions?.includes('ADD_NOTE') || false;
  const canAddContact = job?.permissions?.includes('ADD_CONTACT') || false;
  const canUseSmartTaskManager = user?.isMember;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerVisibleType('');
    setCoverLetter(null);
    setAIEmail(null);
    setIsGeneratingCoverLetter(false);
    setIsGeneratingAIEmail(false);
  };

  async function handleTaskAdd(title: string) {
    if (canUseSmartTaskManager) {
      try {
        await createAiTask({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            data: {
              title,
            },
          },
        });
      } catch (error) {
        console.error(error);
        toast.error(
          `We apologize, but we were unable to create the task at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.`
        );
      }
    } else {
      try {
        await createTask({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            data: {
              title,
            },
          },
        });
      } catch (error) {
        console.error(error);
        toast.error(
          `We apologize, but we were unable to create the task at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.`
        );
      }
    }
  }

  async function handleTaskDelete(value: string) {
    archiveTask({
      variables: {
        userUuid,
        boardUuid,
        jobUuid,
        taskUuid: value,
        isDeleted: true,
      },
    });
  }

  async function handleTaskComplete(taskUuid: string, isCompleted: boolean) {
    completeTask({
      variables: {
        userUuid,
        boardUuid,
        jobUuid,
        taskUuid,
        isCompleted,
      },
    });
  }

  async function handleTaskEdit(taskUuid: string, task: Partial<Task>) {
    try {
      await updateTask({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          taskUuid,
          data: task,
        },
      });
    } catch (error) {
      console.error(error);
      toast.error(
        `We apologize, but we were unable to update the task at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.`
      );
    }
  }

  async function handleJobPublishClick(isPublic: boolean) {
    const toastId = toast.loading(isPublic ? 'Publishing...' : 'Unpublishing...');
    try {
      await publishJob({
        variables: {
          userUuid,
          jobUuid,
          boardUuid,
          isPublic,
        },
      });
      const message = isPublic ? 'The Job Application is now public' : 'The Job Application is now private';
      toast.success(message);
    } catch (error) {
      console.error(error);
      toast.error(
        `We apologize, but we were unable to ${
          isPublic ? 'publish' : 'unpublish'
        } your Job's Board at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.`
      );
    } finally {
      toast.dismiss(toastId);
    }
  }

  async function handleSaveCoverLetter(state: any) {
    const { uuid } = state;
    try {
      if (uuid) {
        const result = await updateCoverLetter({
          variables: {
            userUuid,
            jobUuid,
            boardUuid,
            coverLetterUuid: uuid,
            state,
          },
        });
        const { data } = result;
        if (data?.updateCoverLetter) {
          return data?.updateCoverLetter.uuid;
        }
      } else {
        const result = await createCoverLetter({
          variables: {
            userUuid,
            jobUuid,
            boardUuid,
            state,
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_JOB, variables: { userUuid, boardUuid, jobUuid } }],
        });
        const { data } = result;
        if (data?.createCoverLetter) {
          return data?.createCoverLetter.uuid;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleRenderCoverLetter(state: any) {
    let taskId;
    try {
      taskId = toast.loading('The cover letter is been rendered...', {
        autoClose: 10000,
        position: 'top-left',
      });
      const result = await renderCoverLetter({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          state,
        },
      });
      if (result.data?.renderCoverLetter) {
        window.open(result.data?.renderCoverLetter);
      } else {
        throw new Error('Unable to render cover letter');
      }
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to render a cover letter. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleGenerateCoverLetter() {
    try {
      setIsGeneratingCoverLetter(true);
      const taskId = toast.loading('The cover letter is been generated...', {
        autoClose: 20000,
        position: 'top-left',
      });
      await prepareAICoverLetter({
        variables: {
          taskId: taskId as string,
          userUuid,
          boardUuid,
          jobUuid,
        },
      });
      setDrawerVisibleType('generate_ai_cover_letter');
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to create a cover letter. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      setIsGeneratingCoverLetter(false);
    } finally {
    }
  }

  async function handleSaveAIEmail(aiEmail: Partial<AiEmail>): Promise<void> {
    const { uuid, subject, body, category, contactEmail, contactName } = aiEmail;
    try {
      if (uuid) {
        const result = await updateAIEmail({
          variables: {
            userUuid,
            jobUuid,
            boardUuid,
            aiEmailUuid: uuid,
            data: {
              category,
              contactEmail,
              contactName,
              subject,
              body,
            },
          },
        });
        const { data } = result;
        if (data?.updateAIEmail) {
          return data?.updateAIEmail;
        }
      } else {
        const result = await createAIEmail({
          variables: {
            userUuid,
            jobUuid,
            boardUuid,
            data: {
              category,
              contactEmail,
              contactName,
              subject,
              body,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_JOB, variables: { userUuid, boardUuid, jobUuid } }],
        });
        const { data } = result;
        console.log(data);
        if (data?.createAIEmail) {
          return data?.createAIEmail;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleGenerateAIEmail(category: EmailCategory) {
    try {
      setIsGeneratingAIEmail(true);
      const taskId = toast.loading('The email is been generated...', {
        autoClose: 20000,
        position: 'top-left',
      });
      await prepareAIEmail({
        variables: {
          taskId: taskId as string,
          userUuid,
          boardUuid,
          jobUuid,
          category,
        },
      });
      setDrawerVisibleType('generate_ai_email');
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to create a cover letter. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      setIsGeneratingAIEmail(false);
    }
  }

  async function handleImproveDescription(
    description: string,
    writingStyle: WritingStyle,
    count = 1
  ): Promise<string[]> {
    const taskId = toast.loading('The description is been generated...', {
      autoClose: 20000,
      position: 'top-left',
    });

    try {
      const { data } = await improveDescription({
        variables: {
          data: {
            description,
            writingStyle,
            count,
          },
        },
      });
      return data?.improveDescription || [];
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to create a description. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      return [];
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleImproveCoverLetter(value: any, writingStyle: WritingStyle, count = 1): Promise<string[]> {
    const taskId = toast.loading('🤖 The AI is brewing... ☕️', {
      autoClose: 20000,
      position: 'top-left',
    });

    try {
      const { data } = await improveCoverLetter({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          data: {
            value,
            writingStyle,
            count,
          },
        },
      });

      return data?.improveCoverLetter || [];
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to improve the cover letter. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage, {
        autoClose: 20000,
        position: 'top-left',
      });
      return [];
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleFixTypo(description: string): Promise<string> {
    const taskId = toast.loading('The selection is being spell-checked...', {
      autoClose: 20000,
    });

    try {
      const { data } = await fixTypo({
        variables: {
          data: {
            description,
          },
        },
      });
      return data?.fixTypo || '';
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to spell-checked your selected. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
      return '';
    } finally {
      toast.dismiss(taskId);
    }
  }

  function handleDeleteJobClick() {
    setIsModalVisible(true);
    setModalVisibleType('delete_job');
  }

  function handleEditJobClick(event: any) {
    event.preventDefault();
    navigate(
      formatUrl(`${Urls.JobUpdateHome}/${Urls.JobUpdateStep1}`, {
        userUuid,
        boardUuid,
        jobUuid,
      }),
      {
        state: {
          from: `/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}`,
        },
      }
    );
  }

  function handleJobInviteClick() {
    setIsModalVisible(true);
    setModalVisibleType('invite_job');
  }

  function handleFollowerDeleteClick(follower: User) {
    setIsModalVisible(true);
    setModalVisibleType('confirm_remove_follower');
    setFollower(follower);
  }

  function handleAddEventClick() {
    setIsModalVisible(true);
    setModalVisibleType('add_event');
  }

  function handleAddNoteEventClick() {
    setIsModalVisible(true);
    setModalVisibleType('add_note');
  }

  function handleAddContactClick() {
    setIsModalVisible(true);
    setModalVisibleType('add_contact');
  }

  function handleChangeJobStatusClick(jobStatus: JobStatus) {
    setIsModalVisible(true);
    switch (jobStatus) {
      case JobStatus.Applied:
        setModalVisibleType('apply_job');
        break;
      case JobStatus.Interview:
        setModalVisibleType('interview_job');
        break;
      case JobStatus.Offer:
        setModalVisibleType('offer_job');
        break;
      case JobStatus.Accepted:
        setModalVisibleType('accept_job');
        break;
      case JobStatus.Closed:
        setModalVisibleType('close_job');
        break;
    }
  }

  // SUBMIT FORMS
  async function handleDeleteConfirmSubmit() {
    try {
      await archiveJob({
        variables: {
          boardUuid,
          jobUuid,
          isDeleted: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOBS,
            variables: {
              where: {
                boardUuid,
                userUuid,
                isDeleted: false,
              },
              sort: { direction: SortDirection.Desc, field: 'createdAt' },
            },
          },
        ],
      });
      setIsModalVisible(false);
      toast.success(`The job ${job?.jobTitle} was successfully deleted.`);
      navigate(`/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}`);
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleUpsertEventSubmit(values: any, event?: EventType) {
    if (event) {
      try {
        await updateEvent({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            eventUuid: event.uuid,
            data: {
              ...values,
              startAt: `${values.startAt}:00Z`,
              endAt: values.endAt ? `${values.endAt}:00Z` : null,
            },
          },
        });
        setIsModalVisible(false);
        toast.success('Event updated successfully');
      } catch (error) {
        console.error(error);
        toast.error(
          'Apologies, we were unable to update the event. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
        );
      } finally {
        setIsModalVisible(false);
      }
    } else {
      try {
        await createEvent({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            data: {
              ...values,
              startAt: `${values.startAt}:00Z`,
              endAt: values.endAt ? `${values.endAt}:00Z` : null,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_JOB, variables: { userUuid, boardUuid, jobUuid } }],
        });
        setIsModalVisible(false);
        toast.success('Event created successfully');
      } catch (error) {
        console.error(error);
        toast.error(
          'Apologies, we were unable to create the event. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
        );
      } finally {
        setIsModalVisible(false);
      }
    }
  }

  async function handleUpsertNoteSubmit(values: any, note?: NoteType) {
    if (note) {
      try {
        await updateNote({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            noteUuid: note.uuid,
            data: {
              ...values,
            },
          },
        });
        toast.success('Note updated successfully');
      } catch (error) {
        console.error(error);
        toast.error(
          'Apologies, we were unable to update the note. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
        );
      } finally {
        setIsModalVisible(false);
      }
    } else {
      try {
        await createNote({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            data: {
              ...values,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_JOB, variables: { userUuid, boardUuid, jobUuid } }],
        });
        setIsModalVisible(false);
        toast.success('Note created successfully');
      } catch (error) {
        console.error(error);
        toast.error(
          'Apologies, we were unable to create the note. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
        );
      } finally {
        setIsModalVisible(false);
      }
    }
  }

  async function handleUpsertContactSubmit(values: any, contact?: ContactType) {
    if (contact) {
      try {
        await updateContact({
          variables: {
            entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
            userUuid,
            boardUuid,
            jobUuid,
            contactUuid: contact.uuid,
            data: values,
          },
        });
        toast.success('The contact was successfully updated for this Job Application');
      } catch (error) {
        console.error(error);
      } finally {
        setIsModalVisible(false);
      }
    } else {
      try {
        await createContact({
          variables: {
            entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
            userUuid,
            boardUuid,
            jobUuid,
            data: values,
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_JOB, variables: { userUuid, boardUuid, jobUuid } }],
        });
        toast.success('A contact has successfully been created for this Job Application');
      } catch (error) {
        console.error(error);
        toast.error(
          'Apologies, we were unable to create the contact on this job application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
        );
      } finally {
        setIsModalVisible(false);
      }
    }
  }

  async function handleDeleteContactConfirmSubmit() {
    if (!contact || !contact.uuid) return;

    try {
      await archiveContact({
        variables: {
          entityType: boardUuid ? EntityType.Job : EntityType.Jobpost,
          userUuid,
          boardUuid,
          jobUuid,
          contactUuid: contact.uuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success('Contact deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the event. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleDeleteEventConfirmSubmit() {
    if (!event) return;

    try {
      await archiveEvent({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          eventUuid: event.uuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success('Event deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the event. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleDeleteNoteConfirmSubmit() {
    if (!note) return;

    try {
      await archiveNote({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          noteUuid: note.uuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success('Note deleted successfully');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the note. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleDeleteAIEmailConfirmSubmit() {
    if (!aiEmail) return;
    if (!aiEmail.uuid) return;

    try {
      await archiveAIEmail({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          aiEmailUuid: aiEmail.uuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success('AI Email successfully deleted');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the AI Generated Email. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleDeleteCoverLetterConfirmSubmit() {
    if (!coverLetter) return;
    if (!coverLetter.uuid) return;

    try {
      await archiveCoverLetter({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          coverLetterUuid: coverLetter.uuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success('The cover letter was successfully deleted');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the cover letter. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
      setCoverLetter(null);
    }
  }

  async function handleInvite(values: any) {
    try {
      await inviteUserOnJob({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          data: {
            email: values.email,
            subject: values.subject,
            emailTemplate: EmailTemplate.InviteJobAgent,
            params: {
              content: values.content,
              ctaUrl: `${import.meta.env.VITE_APP_URL}/${Urls.Jobs}/${formatUrl(Urls.JobView, {
                userUuid,
                boardUuid,
                jobUuid,
              })}`,
            },
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_JOB,
            variables: { userUuid, boardUuid, jobUuid },
          },
        ],
      });
      toast.success('Agent/Recruiter invited successfully to collaborate on this Job Application');
    } catch (error) {
      console.error(error);
      toast.error(
        'We apologize, but we were unable to send an invite at this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleFollowerDeleteSubmit() {
    try {
      if (!follower) throw new Error('Follower is not defined');

      await unfollowJob({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          followingUserUuid: follower?.uuid,
        },
        awaitRefetchQueries: true,
        refetchQueries:
          userUuid === user?.uuid
            ? [
                {
                  query: GET_JOB,
                  variables: { userUuid, boardUuid, jobUuid },
                },
              ]
            : [],
      });

      toast.success(
        'The collaboration with the agent/recruiter has been successfully disabled for this Job Application'
      );

      if (userUuid !== user?.uuid) {
        navigate(`/${Urls.Dashboard}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to cancel the collaboration on this job application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setFollower(null);
      setIsModalVisible(false);
    }
  }

  async function handleApplyJobSubmit() {
    try {
      updateJobStatus({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          status: JobStatus.Applied,
        },
      });
      toast.success('The job status for the Job Application has been updated');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update the job status on the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleInterviewJobSubmit(values: {
    startAt: string;
    endAt: string;
    type: EventType;
    description: string;
  }) {
    try {
      if (values.startAt && values.endAt && values.type) {
        await createEvent({
          variables: {
            userUuid,
            boardUuid,
            jobUuid,
            data: {
              ...values,
              startAt: `${values.startAt}:00Z`,
              endAt: values.endAt ? `${values.endAt}:00Z` : null,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [{ query: GET_JOB, variables: { userUuid, boardUuid, jobUuid } }],
        });
        toast.success('The event for the interview was created successfully');
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to create the event. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    }

    try {
      updateJobStatus({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          status: JobStatus.Interview,
        },
      });
      toast.success('The job status for the Job Application has been updated.');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update the job status on the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleOfferJobSubmit() {
    try {
      updateJobStatus({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          status: JobStatus.Offer,
        },
      });

      toast.success('The job status for the Job Application has been updated.');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update the job status on the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleAcceptJobSubmit() {
    try {
      updateJobStatus({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          status: JobStatus.Accepted,
        },
      });

      toast.success('The job status for the Job Application has been updated.');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update the job status on the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  async function handleCloseJobSubmit() {
    try {
      updateJobStatus({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          status: JobStatus.Closed,
        },
      });

      toast.success('The Job Application has now been closed.');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to update the job status on the Job Application. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
    }
  }

  function handleCancel(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    setIsModalVisible(false);
  }

  // Resume
  async function handleResumeDownload(resumeUuid: string): Promise<void> {
    const { isMember } = user || {};
    if (!isMember) {
      navigate(`/${Urls.Checkout}`);
      return;
    }

    let taskId;
    try {
      taskId = toast.loading('The resume is been rendered...', {
        autoClose: 10000,
        position: 'top-left',
      });
      const result = await renderResume({
        variables: {
          userUuid,
          boardUuid,
          resumeUuid,
        },
      });
      if (result.data?.renderResume) {
        window.open(result.data?.renderResume);
      } else {
        throw new Error('Unable to render the resume.');
      }
    } catch (error: any) {
      console.error(error);
      const { graphQLErrors } = error;
      const { message, extensions: { code = '' } = {} } = graphQLErrors?.[0];
      let displayMessage =
        'Apologies, we were unable to render the resume. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.';
      if (code === 'UNAUTHORISED') {
        displayMessage = message;
      }
      toast.error(displayMessage);
    } finally {
      toast.dismiss(taskId);
    }
  }

  async function handleResumeTailorClick(resumeUuid: string) {
    try {
      const result = await tailorResume({
        variables: {
          userUuid,
          boardUuid,
          jobUuid,
          resumeUuid,
        },
      });

      if (result.data?.tailorResume?.uuid) {
        navigate(
          formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
            userUuid,
            boardUuid,
            jobUuid,
            stepId: '1',
            resumeUuid: result.data?.tailorResume?.uuid,
          }),
          {
            state: { from: `/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}` },
          }
        );
      }
    } catch (error: any) {
      let message =
        'We apologize, but we were unable to create your new resume this moment. \n\nPlease refresh the page and attempt again later. \n\nShould the issue persist, feel free to contact us for further assistance.';
      if (error.graphQLErrors[0].extensions.code === 'MAX_JOBS_REACHED') {
        message = 'You have reached the maximum number of resume on this board allowed for your current plan.';
      }
      toast.error(message);
    }
  }

  async function handleResumeEditClick(resumeUuid: string) {
    navigate(
      formatUrl(`${Urls.ResumeUpdateHome}/${Urls.JobResumeUpdateStepGeneric}`, {
        userUuid,
        boardUuid,
        jobUuid,
        resumeUuid,
        stepId: '1',
      }),
      {
        state: { from: `/${Urls.Jobs}/${formatUrl(Urls.JobView, { userUuid, boardUuid, jobUuid })}` },
      }
    );
  }

  async function handleResumeDeleteClick(resume: Resume) {
    setIsModalVisible(true);
    setModalVisibleType('delete_resume');
    setResume(resume);
  }

  async function handleResumeDeleteConfirmSubmit() {
    if (!resume) return;
    if (!resume.uuid) return;
    try {
      await archiveResume({
        variables: {
          boardUuid,
          jobUuid,
          resumeUuid: resume.uuid,
          isDeleted: true,
        },
      });
      setIsModalVisible(false);
      toast.success('The resume was successfully deleted');
    } catch (error) {
      console.error(error);
      toast.error(
        'Apologies, we were unable to delete the resume. Kindly reload the page and try again later. If you are still experiencing issues, please contact us for assistance.'
      );
    } finally {
      setIsModalVisible(false);
      setResume(null);
    }
  }

  let jobComponent = null;
  if (loading) {
    jobComponent = (
      <>
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
        <SkeletonBlock style={{ height: '120px', marginBottom: '40px' }} />
      </>
    );
  } else if (job) {
    const followUpEmails = job?.aiEmails?.filter((aiEmail) => aiEmail.category === EmailCategory.FollowUp) || [];
    const consentEmails = job?.aiEmails?.filter((aiEmail) => aiEmail.category === EmailCategory.Consent) || [];
    const coverLetters = job?.coverLetters || [];
    const hasAiDocuments = followUpEmails.length > 0 || consentEmails.length > 0 || coverLetters.length > 0;
    const currentEvents = job?.events?.filter((event) => event.isDeleted === false) || [];
    const currentContacts = job?.contacts?.filter((contact) => contact.isDeleted === false) || [];
    const currentNotes = job?.notes?.filter((note) => note.isDeleted === false) || [];

    jobComponent = (
      <>
        <PaperCollapse
          title="Job Actions / Permissions"
          action={
            <>
              {canArchiveJob && (
                <PrimaryButton
                  iconRight={<DeleteIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleDeleteJobClick}
                  size="small"
                >
                  Delete Job
                </PrimaryButton>
              )}
              {canEditJob && (
                <PrimaryButton
                  iconRight={<EditIcon color={Colors.White} />}
                  inline={true}
                  onClick={handleEditJobClick}
                  size="small"
                >
                  Edit Job
                </PrimaryButton>
              )}
            </>
          }
        >
          <JobIntroWrapper>
            <Sections>
              <PillsWrapper>
                {canAddEvent && (
                  <Pill color={Colors.Primary} icon={<AddIcon />} onClick={() => handleAddEventClick()}>
                    Add Event
                  </Pill>
                )}

                {canAddNote && (
                  <Pill color={Colors.Primary} icon={<AddIcon />} onClick={() => handleAddNoteEventClick()}>
                    Add Note
                  </Pill>
                )}

                {canAddContact && (
                  <Pill color={Colors.Primary} icon={<AddIcon />} onClick={() => handleAddContactClick()}>
                    Add Contact
                  </Pill>
                )}
                {canInviteJob && (
                  <Pill color={Colors.Primary} icon={<MailSendIcon />} onClick={() => handleJobInviteClick()}>
                    Invite
                  </Pill>
                )}
              </PillsWrapper>
            </Sections>

            <Spacer y={16} />

            <Sections>
              <Section>
                {canInviteJob && (
                  <>
                    <SectionTitle>
                      Followers / Permissions
                      <SectionTitleInfo>
                        <Tooltip
                          title={
                            <IconButton>
                              <InfoIcon size={0.6} />
                            </IconButton>
                          }
                        >
                          <Copy styleLevel={2}>
                            Followers have the flexibility to manage this Job Opportunity seamlessly. They can easily
                            add and update it. Moreover, they can conveniently download all documents attached to your
                            board.
                          </Copy>
                        </Tooltip>
                      </SectionTitleInfo>
                    </SectionTitle>
                    {job.followers?.length && job.followers?.length > 0 ? (
                      <JobPermissions followers={job?.followers} onDeleteClick={handleFollowerDeleteClick} />
                    ) : (
                      <SectionValue>
                        You currently do not have any followers. To expand your network, consider inviting recruiters or
                        agents to collaborate on this Job Application. By doing so, they can access your latest
                        documents and assist in managing this Job Opportunity.
                      </SectionValue>
                    )}
                  </>
                )}
              </Section>
              <Section>
                {canShareJob && (
                  <>
                    <SharedLink url={job.publicUrl || ''} isPublic={!!job.isPublic} />
                    <Spacer y={16} />
                    <Copy styleLevel={2} marginBottom={8}>
                      Activate this option to make your Job Application public. Once activated, simply copy the link and
                      share it with anyone you wish.
                    </Copy>
                    <Copy styleLevel={3} marginBottom={8}>
                      You have the flexibility to disable it whenever you prefer.
                    </Copy>
                    <Copy styleLevel={3} marginBottom={8}>
                      Note that only this job application will be publicly visible including all documents attached to
                      it and the contacts you have added.
                    </Copy>
                    <Copy styleLevel={3} marginBottom={8}>
                      <strong>The job application will not be editable.</strong>
                    </Copy>
                    <Copy styleLevel={3} marginBottom={8}>
                      <strong>The upcoming events will not be visible.</strong>
                    </Copy>
                    <Copy styleLevel={3} marginBottom={8}>
                      <strong>
                        If you want recruiter/agents to edit this Job Opportunity invite them to collaborate on it.
                      </strong>
                    </Copy>
                    <SharedLineWrapper>
                      Enable Shared Link
                      <Switch isOn={!!job.isPublic} onChange={() => handleJobPublishClick(!job.isPublic)} />
                    </SharedLineWrapper>
                  </>
                )}
              </Section>
            </Sections>
          </JobIntroWrapper>
        </PaperCollapse>

        {currentEvents && currentEvents?.length > 0 ? (
          <EventsList>
            {currentEvents.map((currentEvent) => {
              return (
                <Event
                  onEditClick={() => {
                    setEvent(currentEvent);
                    setIsModalVisible(true);
                    setModalVisibleType('edit_event');
                  }}
                  onDeleteClick={() => {
                    setEvent(currentEvent);
                    setIsModalVisible(true);
                    setModalVisibleType('delete_event');
                  }}
                  key={currentEvent.uuid}
                  event={currentEvent}
                />
              );
            })}
            <EventSkeleton onClick={handleAddEventClick} />
          </EventsList>
        ) : (
          <EventsList>
            <EventSkeleton onClick={handleAddEventClick} />
          </EventsList>
        )}

        {currentContacts && currentContacts?.length > 0 && (
          <ContactsList>
            {currentContacts.map((currentContact) => {
              return (
                <Contact
                  onEditClick={() => {
                    setContact(currentContact);
                    setIsModalVisible(true);
                    setModalVisibleType('edit_contact');
                  }}
                  onDeleteClick={() => {
                    setContact(currentContact);
                    setIsModalVisible(true);
                    setModalVisibleType('delete_contact');
                  }}
                  key={currentContact.uuid}
                  {...currentContact}
                />
              );
            })}
          </ContactsList>
        )}

        <ErrorBoundary message="Job View Description">
          <JobDescription
            job={job}
            board={board}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onJobStatusClick={handleChangeJobStatusClick}
            handleGenerateCoverLetter={handleGenerateCoverLetter}
            handleGenerateAIEmail={handleGenerateAIEmail}
            isGeneratingCoverLetter={isGeneratingCoverLetter}
            isGeneratingAiEmail={isGeneratingAiEmail}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <TabController
            headers={[
              { id: 'job', label: 'Job' },
              {
                id: 'resumes',
                label: 'Resumes',
              },
              {
                id: 'ai_documents',
                label: 'AI Documents',
                icon: user?.isMember ? null : <CrownIcon size={1.1} />,
              },
              { id: 'uploaded_files', label: 'Uploaded Files' },
              { id: 'notes', label: 'Notes' },
              { id: 'tasks', label: 'Tasks' },
            ]}
            initialActiveId={activeTab}
            onTabChanged={(tabId) => {
              setActiveTab(tabId);
            }}
          >
            <TabPanel $tabId="job">
              <JobDescriptionStatistic job={job} />
            </TabPanel>
            <TabPanel $tabId="uploaded_files">
              <Suspense fallback={<SmallLoader />}>
                <ErrorBoundary>
                  {job?.files && job.files?.length > 0 && (
                    <ProcessedFiles>
                      {job.files?.map((file) => {
                        return <ProcessedFile file={file} />;
                      })}
                    </ProcessedFiles>
                  )}
                </ErrorBoundary>
              </Suspense>
            </TabPanel>
            <TabPanel $tabId="notes">
              <NotesList>
                {currentNotes && currentNotes?.length > 0 ? (
                  <>
                    {currentNotes.map((note) => (
                      <Note
                        key={note.uuid}
                        title={note.title}
                        description={note.description}
                        onEditClick={() => {
                          setNote(note);
                          setIsModalVisible(true);
                          setModalVisibleType('edit_note');
                        }}
                        onDeleteClick={() => {
                          setNote(note);
                          setIsModalVisible(true);
                          setModalVisibleType('delete_note');
                        }}
                        handleNoteAdd={handleAddNoteEventClick}
                      />
                    ))}
                  </>
                ) : null}
                <NoteSkeleton onEditClick={handleAddNoteEventClick} />
              </NotesList>
            </TabPanel>
            <TabPanel $tabId="tasks">
              {!user?.isMember && (
                <InfoAlert icon={<CrownIcon size={2} />}>
                  Upgrade to one of our Premium Plans to get the Smart Task Manager.{' '}
                  <Link to="/checkout">Learn more...</Link>
                </InfoAlert>
              )}
              <TaskManager
                isSmart={canUseSmartTaskManager}
                tasks={job.tasks || []}
                handleAddTask={handleTaskAdd}
                handleCompleteTask={handleTaskComplete}
                handleDeleteTask={handleTaskDelete}
                handleEditTask={handleTaskEdit}
              />
            </TabPanel>
            <TabPanel $tabId="ai_documents">
              {/* <pre>{JSON.stringify({ coverLetter: coverLetter }, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify({ consentEmails: consentEmails.length }, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify({ followUpEmails: followUpEmails.length }, null, 2)}</pre> */}
              {!user?.isMember ? (
                <InfoAlert icon={<CrownIcon size={2} />}>
                  Upgrade to one of our Premium Plans to get AI suggestions on your Cover Letters or Supporting Emails.{' '}
                  <Link to="/checkout">Learn more...</Link>
                </InfoAlert>
              ) : (
                <TwoColumnWrapper>
                  <TwoColumnLeft>
                    {job?.coverLetters?.length && job.coverLetters?.length > 0 ? (
                      <AIEmailList>
                        {job.coverLetters.map((coverLetter) => {
                          return (
                            <AICoverLetterListItem
                              key={coverLetter.uuid}
                              coverLetter={coverLetter}
                              onEditClick={() => {
                                setCoverLetter(coverLetter);
                                setDrawerVisibleType('generate_ai_cover_letter');
                              }}
                              onDeleteClick={() => {
                                setCoverLetter(coverLetter);
                                setIsModalVisible(true);
                                setModalVisibleType('delete_ai_cover_letter');
                              }}
                              handleDownload={async () => {
                                await handleRenderCoverLetter(coverLetter);
                              }}
                            />
                          );
                        })}
                      </AIEmailList>
                    ) : (
                      <>
                        <CallToAction
                          title="Cover Letter"
                          actionButton={
                            <PrimaryButton
                              inline={true}
                              onClick={handleGenerateCoverLetter}
                              iconRight={<SparklingFullIcon size={1.3} color={Colors.White} />}
                            >
                              Generate
                            </PrimaryButton>
                          }
                        >
                          <Copy isBold={true} styleLevel={1} marginBottom={8} color={Colors.PrimaryDarkest}>
                            Need to secure your ideal position with our effective cover letter template?
                          </Copy>
                          <Copy isBold={true} styleLevel={2} marginBottom={16} color={Colors.PrimaryDarkest}>
                            Speed up the recruiting process by quickly providing a complete consent to your recruiter.
                            Utilize our AI to compose a polished consent email. Edit and save it for future use with
                            ease.
                          </Copy>
                        </CallToAction>
                      </>
                    )}

                    <Spacer y={16} />

                    {followUpEmails && followUpEmails.length > 0 ? (
                      <AIEmailList>
                        {followUpEmails.map((aiEmail) => {
                          return (
                            <>
                              <AIEmailListItem
                                aiEmail={aiEmail}
                                onEditClick={() => {
                                  setAIEmail(aiEmail);
                                  setDrawerVisibleType('generate_ai_email');
                                }}
                                onDeleteClick={() => {
                                  setAIEmail(aiEmail);
                                  setIsModalVisible(true);
                                  setModalVisibleType('delete_ai_email');
                                }}
                              />
                            </>
                          );
                        })}
                      </AIEmailList>
                    ) : (
                      <>
                        <CallToAction
                          title="Follow Up Email"
                          actionButton={
                            <PrimaryButton
                              inline={true}
                              onClick={() => handleGenerateAIEmail(EmailCategory.FollowUp)}
                              iconRight={<SparklingFullIcon size={1.3} color={Colors.White} />}
                            >
                              Generate
                            </PrimaryButton>
                          }
                        >
                          <>
                            <Copy isBold={true} styleLevel={1} marginBottom={8} color={Colors.PrimaryDarkest}>
                              Still waiting to hear about your application?
                            </Copy>
                            <Copy isBold={true} styleLevel={2} marginBottom={16} color={Colors.PrimaryDarkest}>
                              Take charge and follow up seamlessly! Utilize our AI to compose a tailored follow-up
                              email. Edit and save it for future use with ease!
                            </Copy>
                          </>
                        </CallToAction>
                      </>
                    )}

                    <Spacer y={16} />

                    {consentEmails && consentEmails?.length > 0 ? (
                      <AIEmailList>
                        {consentEmails.map((aiEmail) => {
                          return (
                            <>
                              <AIEmailListItem
                                aiEmail={aiEmail}
                                onEditClick={() => {
                                  setAIEmail(aiEmail);
                                  setDrawerVisibleType('generate_ai_email');
                                }}
                                onDeleteClick={() => {
                                  setAIEmail(aiEmail);
                                  setIsModalVisible(true);
                                  setModalVisibleType('delete_ai_email');
                                }}
                              />
                            </>
                          );
                        })}
                      </AIEmailList>
                    ) : (
                      <>
                        <CallToAction
                          title="Consent Email"
                          actionButton={
                            <PrimaryButton
                              inline={true}
                              onClick={() => handleGenerateAIEmail(EmailCategory.Consent)}
                              iconRight={<SparklingFullIcon size={1.3} color={Colors.White} />}
                            >
                              Generate
                            </PrimaryButton>
                          }
                        >
                          <>
                            <Copy isBold={true} styleLevel={1} marginBottom={8} color={Colors.PrimaryDarkest}>
                              Need to give consent to a recruiter to be represented?
                            </Copy>
                            <Copy isBold={true} styleLevel={2} marginBottom={16} color={Colors.PrimaryDarkest}>
                              Take charge and follow up seamlessly! Utilize our AI to compose a tailored consent email.
                              Edit and save it for future use with ease!
                            </Copy>
                          </>
                        </CallToAction>
                      </>
                    )}
                  </TwoColumnLeft>
                  {hasAiDocuments ? (
                    <>
                      <TwoColumnRight>
                        {coverLetters.length > 0 ? (
                          <CallToAction
                            title="Cover Letter"
                            actionButton={
                              <PrimaryButton
                                inline={true}
                                onClick={handleGenerateCoverLetter}
                                size="small"
                                iconRight={<SparklingFullIcon size={1.3} color={Colors.White} />}
                              >
                                Generate
                              </PrimaryButton>
                            }
                          ></CallToAction>
                        ) : null}

                        {followUpEmails.length > 0 ? (
                          <CallToAction
                            title="Follow Up Email"
                            actionButton={
                              <PrimaryButton
                                inline={true}
                                onClick={() => handleGenerateAIEmail(EmailCategory.FollowUp)}
                                size="small"
                                iconRight={<SparklingFullIcon size={1.3} color={Colors.White} />}
                              >
                                Generate
                              </PrimaryButton>
                            }
                          >
                            <></>
                          </CallToAction>
                        ) : null}

                        {consentEmails.length > 0 ? (
                          <CallToAction
                            title="Consent Email"
                            actionButton={
                              <PrimaryButton
                                inline={true}
                                onClick={() => handleGenerateAIEmail(EmailCategory.Consent)}
                                size="small"
                                iconRight={<SparklingFullIcon size={1.3} color={Colors.White} />}
                              >
                                Generate
                              </PrimaryButton>
                            }
                          >
                            <></>
                          </CallToAction>
                        ) : null}
                      </TwoColumnRight>
                    </>
                  ) : null}
                </TwoColumnWrapper>
              )}
            </TabPanel>
            <TabPanel $tabId="resumes">
              <Suspense fallback={<SmallLoader />}>
                <ErrorBoundary>
                  <ProcessedFiles>
                    {board?.files && board.files?.length > 0 && (
                      <>
                        {board?.files
                          .filter((file) => file.aiIsValidResume === true || file.isProcessed === false)
                          .map((file) => {
                            return <ProcessedFile key={file.uuid} file={file} />;
                          })}
                      </>
                    )}
                    {board?.resumes && board.resumes?.length > 0 && (
                      <>
                        {board?.resumes.map((resume) => {
                          return (
                            <ProcessedResume
                              key={resume.uuid}
                              resume={resume}
                              handleDownloadClick={() => handleResumeDownload(resume.uuid)}
                              handleTargetClick={() => handleResumeTailorClick(resume.uuid)}
                            />
                          );
                        })}
                      </>
                    )}
                    {job?.resumes && job.resumes?.length > 0 && (
                      <>
                        {job?.resumes.map((resume) => {
                          return (
                            <ProcessedResume
                              key={resume.uuid}
                              resume={resume}
                              handleEditClick={() => handleResumeEditClick(resume.uuid)}
                              handleDownloadClick={() => handleResumeDownload(resume.uuid)}
                              handleDeleteClick={() => handleResumeDeleteClick(resume)}
                              isTargeted={true}
                            />
                          );
                        })}
                      </>
                    )}
                  </ProcessedFiles>
                </ErrorBoundary>
              </Suspense>
            </TabPanel>
          </TabController>
        </ErrorBoundary>
      </>
    );
  } else {
    jobComponent = <GenericErrorAlert key="error" />;
  }

  return (
    <ViewWrapper>
      <Breadcrumbs>
        <Breadcrumb>
          <Link to={`/${Urls.Boards}`}>All Boards</Link>
        </Breadcrumb>
        <Breadcrumb>
          <Link to={`/${Urls.Boards}/${formatUrl(Urls.BoardView, { userUuid, boardUuid })}`}>
            Board: {board?.title}
          </Link>
        </Breadcrumb>
        <Breadcrumb>Job: {job?.jobTitle}</Breadcrumb>
      </Breadcrumbs>

      {jobComponent}

      <Suspense fallback={<SmallLoader />}>
        <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible}>
          {modalVisibleType === 'accept_job' && (
            <AcceptJobModal handleCancel={handleCancel} handleSubmit={handleAcceptJobSubmit}></AcceptJobModal>
          )}
          {modalVisibleType === 'add_contact' && (
            <AddContactModal handleCancel={handleCancel} handleSubmit={handleUpsertContactSubmit} />
          )}
          {modalVisibleType === 'add_event' && (
            <AddEventModal handleCancel={handleCancel} handleSubmit={handleUpsertEventSubmit} />
          )}
          {modalVisibleType === 'add_note' && (
            <AddNoteModal handleCancel={handleCancel} handleSubmit={handleUpsertNoteSubmit} />
          )}
          {modalVisibleType === 'edit_event' && (
            <AddEventModal event={event} handleCancel={handleCancel} handleSubmit={handleUpsertEventSubmit} />
          )}
          {modalVisibleType === 'edit_note' && (
            <AddNoteModal note={note} handleCancel={handleCancel} handleSubmit={handleUpsertNoteSubmit} />
          )}
          {modalVisibleType === 'edit_contact' && (
            <AddContactModal contact={contact} handleCancel={handleCancel} handleSubmit={handleUpsertContactSubmit} />
          )}
          {modalVisibleType === 'delete_ai_email' && (
            <ConfirmDeleteModal
              title="Delete"
              handleCancel={handleCancel}
              handleSubmit={() => handleDeleteAIEmailConfirmSubmit()}
            >
              <Copy marginBottom={16}>Delete the generated AI Email</Copy>
              <Copy styleLevel={2}>The generated AI email will be deleted from this Job Opportunity.</Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_resume' && (
            <ConfirmDeleteModal
              title="Delete"
              handleCancel={handleCancel}
              handleSubmit={handleResumeDeleteConfirmSubmit}
            >
              <Copy marginBottom={16}>Delete the resume</Copy>
              <Copy styleLevel={2}>The targeted resume will be deleted from this Job Opportunity.</Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_ai_cover_letter' && (
            <ConfirmDeleteModal
              title="Delete"
              handleCancel={handleCancel}
              handleSubmit={() => handleDeleteCoverLetterConfirmSubmit()}
            >
              <Copy marginBottom={16}>Delete the generated AI Cover Letter</Copy>
              <Copy styleLevel={2}>The generated AI cover letter will be deleted from this Job Opportunity.</Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_note' && (
            <ConfirmDeleteModal
              title="Delete"
              handleCancel={handleCancel}
              handleSubmit={() => handleDeleteNoteConfirmSubmit()}
            >
              <Copy marginBottom={16}>Delete the note</Copy>
              <Copy styleLevel={2}>The note will be deleted from this Job Opportunity.</Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_event' && (
            <ConfirmDeleteModal
              title="Delete Event"
              handleCancel={handleCancel}
              handleSubmit={() => handleDeleteEventConfirmSubmit()}
            >
              <Copy marginBottom={16}>Delete the event</Copy>
              <Copy styleLevel={2}>The event will be deleted from this Job Opportunity.</Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_contact' && (
            <ConfirmDeleteModal
              title="Delete Contact"
              handleCancel={handleCancel}
              handleSubmit={() => handleDeleteContactConfirmSubmit()}
            >
              <Copy marginBottom={16}>Delete the contact</Copy>
              <Copy styleLevel={2}>The contact will be deleted from this Job Opportunity.</Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'delete_job' && (
            <ConfirmDeleteModal
              title="Delete JOB"
              handleCancel={handleCancel}
              handleSubmit={() => handleDeleteConfirmSubmit()}
            >
              <Copy marginBottom={16}>Delete the Job Opportunity.</Copy>
              <Copy styleLevel={2} marginBottom={16}>
                The job listing for '{job?.jobTitle}' will be <b>removed</b> from this Jobs Board.
              </Copy>
              <Copy styleLevel={2} marginBottom={16}>
                Please confirm.
              </Copy>
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'invite_job' && (
            <InviteUserOnJobModal handleCancel={handleCancel} handleSubmit={handleInvite} />
          )}
          {modalVisibleType === 'confirm_remove_follower' && (
            <ConfirmDeleteModal
              title="Cancel the collaboration"
              handleCancel={handleCancel}
              handleSubmit={handleFollowerDeleteSubmit}
            >
              {follower?.uuid === user?.uuid ? (
                <>
                  Your collaborator on this <b>Job Application</b> will been restricted from making any further updates.
                  <br />
                  <br />
                  Your editing privileges for this position will been <b>revoked</b> permanently.
                </>
              ) : (
                <>
                  The collaborator {follower?.name ? follower?.name : follower?.nickname}{' '}
                  {follower?.email && `[${follower?.email}]`} will been restricted <br />
                  from making any further updates to this Job Application.
                  <br />
                  <br />
                  Their editing privileges for this position will been <b>revoked</b>.
                </>
              )}
            </ConfirmDeleteModal>
          )}
          {modalVisibleType === 'apply_job' && (
            <ApplyJobModal job={job} handleCancel={handleCancel} handleSubmit={handleApplyJobSubmit}></ApplyJobModal>
          )}
          {modalVisibleType === 'interview_job' && (
            <InterviewJobModal handleCancel={handleCancel} handleSubmit={handleInterviewJobSubmit}></InterviewJobModal>
          )}
          {modalVisibleType === 'offer_job' && (
            <OfferJobModal handleCancel={handleCancel} handleSubmit={handleOfferJobSubmit}></OfferJobModal>
          )}
          {modalVisibleType === 'close_job' && (
            <CloseJobModal handleCancel={handleCancel} handleSubmit={handleCloseJobSubmit}></CloseJobModal>
          )}
        </Modal>

        <Drawer anchor="right" open={Boolean(drawerVisibleType !== '')} onClose={toggleDrawer(false)}>
          <DrawerWrapper>
            {drawerVisibleType === 'generate_ai_email' && (
              <GenerateAIEmail
                isGeneratingAiEmail={isGeneratingAiEmail}
                aiEmail={aiEmail}
                job={job}
                handleGenerateAIEmail={handleGenerateAIEmail}
                handleSave={handleSaveAIEmail}
                handleImproveDescription={handleImproveDescription}
                handleFixTypo={handleFixTypo}
                onCloseClick={toggleDrawer(false)}
              ></GenerateAIEmail>
            )}
            {drawerVisibleType === 'generate_ai_cover_letter' && (
              <GenerateAICoverLetter
                board={board}
                job={job}
                user={user}
                isGeneratingCoverLetter={isGeneratingCoverLetter}
                coverLetter={coverLetter}
                handleGenerateCoverLetter={handleGenerateCoverLetter}
                handleRenderCoverLetter={handleRenderCoverLetter}
                handleSave={handleSaveCoverLetter}
                handleImproveCoverLetter={handleImproveCoverLetter}
                handleFixTypo={handleFixTypo}
                onCloseClick={toggleDrawer(false)}
              ></GenerateAICoverLetter>
            )}
          </DrawerWrapper>
        </Drawer>
      </Suspense>
    </ViewWrapper>
  );
};
