import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateTaskArgs } from '../../generated/types';

export const CREATE_TASK = gql`
  mutation CreateTask($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: TaskInputData!) {
    createTask(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid
      title
      description
      dueDate
      isFullDay
      priority
      order
      isCompleted
      isDeleted
    }
  }
`;

export const useCreateTask = (options?: MutationHookOptions<Pick<Mutation, 'createTask'>, MutationCreateTaskArgs>) =>
  useMutation<Pick<Mutation, 'createTask'>, MutationCreateTaskArgs>(CREATE_TASK, {
    update(cache, { data }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const createTask = data?.createTask;
      if (!jobUuid) return;
      if (!createTask) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          tasks(existingTasks = []) {
            const newTaskRef = cache.writeFragment({
              data: createTask,
              fragment: gql`
                fragment NewTask on Task {
                  uuid
                  title
                  description
                  dueDate
                  isFullDay
                  priority
                  isCompleted
                  isDeleted
                }
              `,
            });
            return [...existingTasks, newTaskRef];
          },
        },
      });
    },
    ...options,
  });
