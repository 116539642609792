import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';

export const Container = styled.div`
  padding: 16px;
  flex: 1 1 320px;
  max-width: 400px;
  position: relative;

  // this is important to make the paper 100% height
  > div {
    height: 100%;
    /* border: 1px solid red; */
    padding: 16px;
  }
`;

export const EditableContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const Actions = styled.div`
  position: absolute;
  display: flex;
  gap: 8px;
  top: 16px;
  right: 8px;
  padding: 8px;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const LineWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  gap: 4px;
`;

export const RichTextWrapper = styled.div`
  /* border: 1px solid red; */
  margin-left: 12px;
`;

export const ContainerList = styled.div`
  border: 1px dotted ${Colors.Contrast};
  display: flex;
  gap: 8px;
  padding: 0 0 16px 0;
  flex-wrap: wrap;
`;
