import htmlToPdfmake from 'html-to-pdfmake';
// pdfFont fails on build so using custom font
// https://pdfmake.github.io/docs/0.1/fonts/custom-fonts-client-side/url/
import pdfMake from 'pdfmake/build/pdfmake';

pdfMake.fonts = {
  // download default Roboto font from cdnjs.com
  Roboto: {
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

export function generatePdf(html: string, fileName: string) {
  const pdfMakeReadyHtml = htmlToPdfmake(html);
  var docDefinition = {
    content: [pdfMakeReadyHtml],
    styles: {
      // 'html-strong': {
      //   background: 'yellow', // it will add a yellow background to all <STRONG> elements
      // },
    },
  };

  pdfMake.createPdf(docDefinition).download(fileName);
}
