import styled from 'styled-components';
import { useState } from 'react';

import { TaskManagerProps } from './TaskManager.types';
import { Container } from './Task.styles';
import { TaskManagerTextInput, TaskManagerTextInputWithAI } from './TaskManagerTextInput';
import { TaskManagerItemsList } from './TaskManagerItemsList';
import { TinyLoader } from '../../atoms/Loader';
import { Task } from '../../../generated/types';

export const TaskManager = ({
  tasks,
  isSmart = false,
  handleAddTask,
  handleCompleteTask,
  handleDeleteTask,
  handleEditTask,
  ...otherProps
}: TaskManagerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  let ordererTasks: Task[] = [];
  if (tasks && Array.isArray(tasks)) {
    ordererTasks = [...tasks].sort((taskA, taskB) => {
      const orderA = taskA.order || 0;
      const orderB = taskB.order || 0;
      if (orderA < orderB) {
        return -1;
      }
      if (orderA > orderB) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <Container {...otherProps}>
      {isSmart ? (
        <TaskManagerTextInputWithAI
          onSave={async ({ title }: { title: string }) => {
            if (title.length !== 0) {
              try {
                setIsLoading(true);
                await handleAddTask(title);
              } catch (error) {
                console.error(error);
              } finally {
                setIsLoading(false);
              }
            }
          }}
          placeholder="What needs to be done?"
        />
      ) : (
        <TaskManagerTextInput
          onSave={async ({ title }: { title: string }) => {
            if (title.length !== 0) {
              try {
                setIsLoading(true);
                await handleAddTask(title);
              } catch (error) {
                console.error(error);
              } finally {
                setIsLoading(false);
              }
            }
          }}
          placeholder="What needs to be done?"
        />
      )}

      <TaskManagerItemsList
        tasks={ordererTasks}
        isSmart={isSmart}
        handleEditTask={handleEditTask}
        handleDeleteTask={handleDeleteTask}
        handleCompleteTask={handleCompleteTask}
      />

      {isLoading && (
        <CentralWrapper>
          <TinyLoader />
        </CentralWrapper>
      )}
    </Container>
  );
};

const CentralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
