import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateEventArgs } from '../../generated/types';

export const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $eventUuid: String!
    $data: EventInputData!
  ) {
    updateEvent(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, eventUuid: $eventUuid, data: $data) {
      uuid
      meetingUrl
      description
      type
      startAt
      endAt
      location
      locationCity
      locationCountry
      locationLatitude
      locationLongitude
      locationPostCode
      locationStreet
      locationUrl
      description
      updatedAt
      updatedBy {
        uuid
        email
        nickname
        name
      }
    }
  }
`;

export const useUpdateEvent = (options?: MutationHookOptions<Pick<Mutation, 'updateEvent'>, MutationUpdateEventArgs>) =>
  useMutation<Pick<Mutation, 'updateEvent'>, MutationUpdateEventArgs>(UPDATE_EVENT, {
    ...options,
    update(cache, { data: { updateEvent = {} } = {} }) {
      if (!updateEvent.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Event',
          uuid: updateEvent.uuid,
        }),
        fields: {
          meetingUrl() {
            return updateEvent.meetingUrl;
          },
          description() {
            return updateEvent.description;
          },
          type() {
            return updateEvent.type;
          },
          startAt() {
            return updateEvent.startAt;
          },
          endAt() {
            return updateEvent.endAt;
          },
          location() {
            return updateEvent.location;
          },
          locationCity() {
            return updateEvent.locationCity;
          },
          locationCountry() {
            return updateEvent.locationCountry;
          },
          locationLatitude() {
            return updateEvent.locationLatitude;
          },
          locationLongitude() {
            return updateEvent.locationLongitude;
          },
          locationPostCode() {
            return updateEvent.locationPostCode;
          },
          locationStreet() {
            return updateEvent.locationStreet;
          },
          locationUrl() {
            return updateEvent.locationUrl;
          },
          updatedAt() {
            return updateEvent.updatedAt;
          },
        },
      });
    },
  });
