import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateContactArgs } from '../../generated/types';

export const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $entityType: EntityType!
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $contactUuid: String!
    $data: ContactInputData!
  ) {
    updateContact(
      entityType: $entityType
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      contactUuid: $contactUuid
      data: $data
    ) {
      uuid
      fullName
      position
      email
      phoneNumber
      linkedin
      avatar
      isDeleted
      createdAt
      updatedAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedBy {
        uuid
        email
        nickname
        name
      }
    }
  }
`;

export const useUpdateContact = (
  options?: MutationHookOptions<Pick<Mutation, 'updateContact'>, MutationUpdateContactArgs>
) =>
  useMutation<Pick<Mutation, 'updateContact'>, MutationUpdateContactArgs>(UPDATE_CONTACT, {
    ...options,
    update(cache, { data: { updateContact = {} } = {} }) {
      if (!updateContact.uuid) return;
      console.log('>>>>updateContact', updateContact);
      cache.modify({
        id: cache.identify({
          __typename: 'Contact',
          uuid: updateContact.uuid,
        }),
        fields: {
          email() {
            return updateContact.email;
          },
          fullName() {
            return updateContact.fullName;
          },
          linkedin() {
            return updateContact.linkedin;
          },
          phoneNumber() {
            return updateContact.phoneNumber;
          },
          position() {
            return updateContact.position;
          },
          updatedAt() {
            return updateContact.updatedAt;
          },
        },
      });
    },
  });
