import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateAiEmailArgs } from '../../generated/types';

export const CREATE_AI_EMAIL = gql`
  mutation CreateAIEmail($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $data: AIEmailInputData!) {
    createAIEmail(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid
      category
      subject
      body
      isDeleted
      contactEmail
      contactName
    }
  }
`;

export const useCreateAIEmail = (
  options?: MutationHookOptions<Pick<Mutation, 'createAIEmail'>, MutationCreateAiEmailArgs>
) =>
  useMutation<Pick<Mutation, 'createAIEmail'>, MutationCreateAiEmailArgs>(CREATE_AI_EMAIL, {
    ...options,
  });
