import { CalendarDayProps } from './CalendarDay.types';
import { Container, Day, Month } from './CalendarDay.styles';

export const CalendarDay: React.FC<any> = ({ day, month, color, isDisabled, ...otherProps }: CalendarDayProps) => {
  const formattedDay = Number(day) < 10 ? `0${day}` : day;
  return (
    <Container color={color} $isDisabled={isDisabled} {...otherProps}>
      <Month $isDisabled={isDisabled}>{month}</Month>
      <Day color={color} $isDisabled={isDisabled}>
        {formattedDay}
      </Day>
    </Container>
  );
};
