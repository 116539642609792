import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUnfollowJobArgs } from '../../generated/types';

export const UNFOLLOW_JOB = gql`
  mutation UnfollowJob($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $followingUserUuid: String!) {
    unfollowJob(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, followingUserUuid: $followingUserUuid)
  }
`;

export const useUnfollowJob = (options?: MutationHookOptions<Pick<Mutation, 'unfollowJob'>, MutationUnfollowJobArgs>) =>
  useMutation<Pick<Mutation, 'unfollowJob'>, MutationUnfollowJobArgs>(UNFOLLOW_JOB, {
    awaitRefetchQueries: false,
    // onCompleted: (data) => {
    //   console.log('onCompleted', data);
    // },
    ...options,
  });
