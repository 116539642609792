import styled from 'styled-components';
import { Link as LinkReactRouter, To } from 'react-router-dom';

import { media } from '../../../styles/media';
import { Colors } from '../../../styles/colors';
import { convertEnumToString } from '../../../utils/enum';

import { JobDescriptionProps } from './JobDescription.types';
import {
  ChevronRightIcon,
  CyclingIcon,
  DrivingIcon,
  ExternalLinkIcon,
  HomeIcon,
  LinkedinIcon,
  LocationIcon,
  MailIcon,
  MoneyBillIcon,
  PeopleIcon,
  PhoneIcon,
  StarIcon,
  SuitcaseIcon,
  TransitIcon,
  WalkingIcon,
} from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Section } from '../../atoms/Section';
import { HRSection } from '../../atoms/HRSection';
import { GoogleMap } from '../../molecules/GoogleMap/GoogleMap';
import { ErrorBoundary } from '../../molecules/ErrorBoundary';
import { Stepper } from '../../atoms/Stepper';
import { EmailCategory, JobStatus } from '../../../generated/types';
import { PrimaryButton } from '../../atoms/Button';
import { LinkButton } from '../../atoms/Button/Buttons';
import { Avatar } from '../../atoms/Avatar';

export const JobDescription: React.FC<any> = ({
  job,
  board,
  onJobStatusClick,
  isGeneratingAIEmail,
  isGeneratingAICoverLetter,
  handleGenerateAIEmail,
  handleGenerateCoverLetter,
}: React.PropsWithChildren<JobDescriptionProps>) => {
  const {
    isThroughAgency,
    agencyName,
    agentName,
    agentEmail,
    agentPhone,
    company,
    companyLinkedIn,
    companyAvatar,
    companyWebsite,
    companyLocation,
    companyLocationLatitude,
    companyLocationLongitude,
    companyLocationUrl,
    companyDistances,
    jobTitle,
    jobUrl,
    ir35,
    duration,
    rate,
    employmentType,
    jobSeniorityLevel,
    remoteOption,
    createdAt,
    updatedAt,
    status,
    permissions,
  } = job;
  const IsNew = () => {
    const today = new Date();
    const isCreatedAt = new Date(createdAt) > new Date(today.setDate(today.getDate() - 7));
    const isUpdatedAt = new Date(updatedAt) > new Date(today.setDate(today.getDate() - 7));
    return isCreatedAt || isUpdatedAt;
  };
  const homeCoordinates = {
    lat: board?.locationLatitude || undefined,
    lng: board?.locationLongitude || undefined,
  };
  const coordinates = {
    lat: companyLocationLatitude || undefined,
    lng: companyLocationLongitude || undefined,
  };
  const activeStep = getActiveStep(status || JobStatus.Wishlist);
  const canSeeWorkflow = permissions?.includes('VIEW_WORKFLOW') || false;
  const canCreateCoverLetter = permissions?.includes('CREATE_COVER_LETTER') || false;
  const canGenerateEmail = permissions?.includes('GENERATE_EMAIL') || false;

  const steps = [
    'Whishlist',
    activeStep > 1 ? (
      'Applied'
    ) : (
      <PrimaryButton
        onClick={() => onJobStatusClick && onJobStatusClick(JobStatus.Applied)}
        size="tiny"
        inline={true}
        iconRight={<ChevronRightIcon size={1} color={Colors.White} />}
      >
        Apply
      </PrimaryButton>
    ),
    activeStep > 2 ? (
      'Interviewing'
    ) : (
      <PrimaryButton
        onClick={() => onJobStatusClick && onJobStatusClick(JobStatus.Interview)}
        size="tiny"
        inline={true}
        iconRight={<ChevronRightIcon size={1} color={Colors.White} />}
      >
        Interviewing
      </PrimaryButton>
    ),
    activeStep > 3 ? (
      'Offer'
    ) : (
      <PrimaryButton
        onClick={() => onJobStatusClick && onJobStatusClick(JobStatus.Offer)}
        size="tiny"
        inline={true}
        iconRight={<ChevronRightIcon size={1} color={Colors.White} />}
      >
        Offer
      </PrimaryButton>
    ),

    activeStep > 4 ? (
      'Accepted'
    ) : (
      <PrimaryButton
        onClick={() => onJobStatusClick && onJobStatusClick(JobStatus.Accepted)}
        size="tiny"
        inline={true}
        iconRight={<ChevronRightIcon size={1} color={Colors.White} />}
      >
        Accept
      </PrimaryButton>
    ),
  ];
  if (job.status !== JobStatus.Accepted) {
    steps.push(
      activeStep > 4 ? (
        'Close'
      ) : (
        <PrimaryButton
          onClick={() => onJobStatusClick && onJobStatusClick(JobStatus.Closed)}
          size="tiny"
          inline={true}
          iconRight={<ChevronRightIcon size={1} color={Colors.White} />}
        >
          Close
        </PrimaryButton>
      )
    );
  }

  return (
    <Container>
      {canSeeWorkflow && (
        <ApplicationStatus>
          <Stepper activeStep={activeStep} steps={steps}>
            Stepper
          </Stepper>
          <Actions>
            {canCreateCoverLetter && job.status === JobStatus.Wishlist && (
              <Action>
                <ChevronRightIcon />
                <LinkButton
                  disabled={isGeneratingAICoverLetter}
                  onClick={() => {
                    handleGenerateCoverLetter && handleGenerateCoverLetter();
                  }}
                >
                  Generate Cover / Application Letter
                </LinkButton>
              </Action>
            )}
            {canGenerateEmail && job.status === JobStatus.Wishlist && (
              <Action>
                <ChevronRightIcon />
                <LinkButton
                  disabled={isGeneratingAIEmail}
                  onClick={() => {
                    handleGenerateAIEmail && handleGenerateAIEmail(EmailCategory.Consent);
                  }}
                >
                  Generate Consent Email
                </LinkButton>
              </Action>
            )}
            {canGenerateEmail && job.status === JobStatus.Applied && (
              <Action>
                <ChevronRightIcon />
                <LinkButton
                  disabled={isGeneratingAIEmail}
                  onClick={() => {
                    handleGenerateAIEmail && handleGenerateAIEmail(EmailCategory.FollowUp);
                  }}
                >
                  Generate Follow Up Email
                </LinkButton>
              </Action>
            )}
          </Actions>
        </ApplicationStatus>
      )}

      <ErrorBoundary>
        <Intro>
          <IntroMain>
            <MainTitleWrapper>
              <CompanyName>
                {Boolean(companyAvatar) && <Avatar image={companyAvatar} />}
                {company}
                {Boolean(companyLinkedIn) && (
                  <LocationUrl target="_blank" to={companyLinkedIn as To}>
                    <LinkedinIcon size={0.9} />
                  </LocationUrl>
                )}
                {Boolean(companyWebsite) && (
                  <LocationUrl target="_blank" to={companyWebsite as To}>
                    <ExternalLinkIcon size={1} />
                  </LocationUrl>
                )}
              </CompanyName>
              <PillsWrapper>
                {IsNew() && (
                  <Pill icon={<StarIcon />} isSmall={true} color={Colors.Contrast}>
                    New
                  </Pill>
                )}
                {ir35 && (
                  <Pill icon={<MoneyBillIcon />} isSmall={true} color={Colors.Primary}>
                    Within IR35
                  </Pill>
                )}
                {isThroughAgency && (
                  <Pill icon={<PeopleIcon />} isSmall={true} color={Colors.PrimaryDarkest}>
                    Agency
                  </Pill>
                )}
              </PillsWrapper>
            </MainTitleWrapper>
            <JobTitle>
              {jobTitle}
              {Boolean(jobUrl) && (
                <LinkReactRouter target="_blank" to={jobUrl as To}>
                  <ExternalLinkIcon />
                </LinkReactRouter>
              )}
            </JobTitle>
            <Salary>
              {Boolean(rate) && <span>{rate}</span>}
              {Boolean(duration && rate) && <span> / </span>}
              {Boolean(duration) && <span>{duration}</span>}
            </Salary>
            <Employment>
              {employmentType && (
                <EmploymentContent>
                  <SuitcaseIcon size={1.5} />
                  {convertEnumToString('EmploymentType', employmentType)}
                </EmploymentContent>
              )}
              {remoteOption && (
                <EmploymentContent>
                  <HomeIcon size={1.5} />
                  {convertEnumToString('RemoteOption', remoteOption)}
                </EmploymentContent>
              )}
              {jobSeniorityLevel && (
                <EmploymentContent>
                  <StarIcon size={1.5} />
                  {convertEnumToString('SeniorityLevel', jobSeniorityLevel)}
                </EmploymentContent>
              )}
            </Employment>
            {companyLocation && (
              <Location>
                <LocationIcon size={1.5} color={Colors.Contrast} />
                {companyLocation}
                {Boolean(companyLocationUrl) && (
                  <LocationUrl target="_blank" to={companyLocationUrl as To}>
                    <ExternalLinkIcon size={1.1} />
                  </LocationUrl>
                )}
              </Location>
            )}
          </IntroMain>
          <IntroRight>
            <Section>
              {isThroughAgency || agencyName || agentName ? (
                <>
                  <SectionTitle>Agency</SectionTitle>
                  <SectionValue>{agencyName}</SectionValue>
                  <SectionValue>{agentName}</SectionValue>
                  {agentEmail && (
                    <SectionValue>
                      <MailIcon /> {agentEmail}
                    </SectionValue>
                  )}
                  {agentPhone && (
                    <SectionValue>
                      <PhoneIcon /> {agentPhone}
                    </SectionValue>
                  )}
                </>
              ) : (
                <SectionTitle>No Agency</SectionTitle>
              )}
            </Section>
          </IntroRight>
        </Intro>
      </ErrorBoundary>

      <HRSection />

      {coordinates && coordinates.lat && coordinates.lng && (
        <ErrorBoundary>
          <HRSection />
          <MapContainer>
            <MapContainerImage>
              <GoogleMap center={coordinates} home={homeCoordinates} />
            </MapContainerImage>
            <MapContainerDistance>
              {companyDistances?.driving?.distance && (
                <LineWrapper>
                  <DrivingIcon size={1.5} />
                  {companyDistances?.driving?.distance} / {companyDistances?.driving?.duration}
                </LineWrapper>
              )}
              {companyDistances?.walking?.distance && (
                <LineWrapper>
                  <WalkingIcon size={1.1} />
                  {companyDistances?.walking?.distance} / {companyDistances?.walking?.duration}
                </LineWrapper>
              )}
              {companyDistances?.bicycling?.distance && (
                <LineWrapper>
                  <CyclingIcon size={1.5} />
                  {companyDistances?.bicycling?.distance} / {companyDistances?.bicycling?.duration}
                </LineWrapper>
              )}
              {companyDistances?.transit?.distance && (
                <LineWrapper>
                  <TransitIcon size={1.5} />
                  {companyDistances?.transit?.distance} / {companyDistances?.transit?.duration}
                </LineWrapper>
              )}
            </MapContainerDistance>
          </MapContainer>
        </ErrorBoundary>
      )}
    </Container>
  );
};

const Container = styled.div``;

const ApplicationStatus = styled.div`
  display: none;
  margin-bottom: 16px;
  border: 1px dotted ${Colors.Contrast};
  padding: 16px 0 0 0;

  ${media.from_laptop`
    display: block;
  `}
`;

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px 16px 8px 16px;
`;

const Action = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
`;

const Intro = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MainTitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  ${media.from_laptop`
    margin-bottom: 16px;
  `}
`;

const PillsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  gap: 8px;

  ${media.from_laptop`
    width: fit-content;
    gap: 16px;
  `}
`;

const CompanyName = styled.div`
  display: flex;
  font-size: 2rem;
  gap: 8px;
  align-items: center;
  margin-right: 8px;

  ${media.from_laptop`
    margin-bottom: 16px;
  `}
`;

const IntroMain = styled.div`
  flex: 4;
`;

const IntroRight = styled.div`
  flex: 1;
`;

const JobTitle = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 8px;
`;

const Salary = styled.div`
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${Colors.Primary};
  margin-bottom: 8px;
`;

const Location = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1.1rem;
`;

const Employment = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

const EmploymentContent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LineWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 8px;
`;

const SectionTitle = styled.div`
  text-transform: uppercase;
  color: ${Colors.GreyLightest};
  font-size: 1.2rem;
  line-height: 2rem;
`;

const SectionValue = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.GreyDarkest};
  font-size: 1.2rem;
  line-height: 2rem;
`;

const LocationUrl = styled(LinkReactRouter)`
  font-size: 1.3rem;
  transform: translateY(-8px);
`;

const MapContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  height: 400px;
  width: 100%;
`;

const MapContainerImage = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  flex: 4;
`;

const MapContainerDistance = styled.div`
  /* border: 1px solid red; */
  padding: 16px;
  flex: 1;
`;

const getActiveStep = (status: JobStatus) => {
  switch (status) {
    case JobStatus.Scrapped:
      return 0;
    case JobStatus.Wishlist:
      return 1;
    case JobStatus.Applied:
      return 2;
    case JobStatus.Interview:
      return 3;
    case JobStatus.Offer:
      return 4;
    case JobStatus.Accepted:
      return 5;
    case JobStatus.Closed:
      return 5;
    default:
      return 1;
  }
};
