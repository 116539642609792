import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateNoteArgs } from '../../generated/types';

export const UPDATE_NOTE = gql`
  mutation UpdateNote(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $noteUuid: String!
    $data: NoteInputData!
  ) {
    updateNote(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, noteUuid: $noteUuid, data: $data) {
      uuid
      title
      description
      isDeleted
      createdAt
      updatedAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedBy {
        uuid
        email
        nickname
        name
      }
    }
  }
`;

export const useUpdateNote = (options?: MutationHookOptions<Pick<Mutation, 'updateNote'>, MutationUpdateNoteArgs>) =>
  useMutation<Pick<Mutation, 'updateNote'>, MutationUpdateNoteArgs>(UPDATE_NOTE, {
    ...options,
    update(cache, { data: { updateNote = {} } = {} }) {
      if (!updateNote.uuid) return;
      cache.modify({
        id: cache.identify({
          __typename: 'Note',
          uuid: updateNote.uuid,
        }),
        fields: {
          title() {
            return updateNote.title;
          },
          description() {
            return updateNote.description;
          },
          updatedAt() {
            return updateNote.updatedAt;
          },
        },
      });
    },
  });
