import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationPublishJobArgs } from '../../generated/types';

export const PUBLISH_JOB = gql`
  mutation PublishJob($userUuid: String!, $boardUuid: String!, $jobUuid: String!, $isPublic: Boolean!) {
    publishJob(userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, isPublic: $isPublic) {
      uuid
      isPublic
    }
  }
`;

export const usePublishJob = (options?: MutationHookOptions<Pick<Mutation, 'publishJob'>, MutationPublishJobArgs>) =>
  useMutation<Pick<Mutation, 'publishJob'>, MutationPublishJobArgs>(PUBLISH_JOB, {
    ...options,
  });
