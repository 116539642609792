import { toast } from 'react-toastify';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Descendant } from 'slate';

import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';

import { GenerateAIEmailProps } from './GenerateAIEmail.types';
import { CenteredLayout, Container } from './GenerateAIEmail.styles';
import { EmailCategory, WritingStyle } from '../../../generated/types';
import { serializeSlateNodeToHtml, serializeSlateNodeToText } from '../../molecules/SlateEditor/helper';
import { generatePdf } from '../../../utils/pdfMake';
import aiInProgressAnimation from '../../../assets/lotties/ai-in-progress.json';
import { Colors } from '../../../styles/colors';

import { CloseIcon, CopyIcon, DownloadIcon, MailIcon, SparklingIcon } from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Copy } from '../../atoms/Typography';
import { SlateEditor } from '../../molecules/SlateEditor';
import { IconButton } from '../../atoms/Icons/Icon';
import { Placeholder } from '../../atoms/Placeholder';
import { Lottie } from '../../atoms/Lottie';
import { initialState, usePersistReducer } from './Store';
import { TabController, TabPanel } from '../../atoms/Tabs';
import { Form } from '../../atoms/Form/Form';
import { TextInput } from '../../atoms/Form/TextInput';

const SAVING_TIMEOUT = 5000; // 5 seconds
const LOCAL_STORAGE_KEY = 'cover-letter';

export const GenerateAIEmail = ({
  isGeneratingAiEmail,
  aiEmail,
  handleGenerateAIEmail,
  handleSave,
  handleImproveDescription,
  handleFixTypo,
  onCloseClick,
}: React.PropsWithChildren<GenerateAIEmailProps>) => {
  const [_value, copy] = useCopyToClipboard();

  const [state, dispatch] = usePersistReducer(LOCAL_STORAGE_KEY, initialState);

  useEffect(() => {
    if (!isGeneratingAiEmail) {
      dispatch({
        type: 'SET_IS_LOADING',
        payload: false,
      });
      dispatch({
        type: 'INITIALIZE_CONTENT',
        payload: aiEmail,
      });
    }
  }, [isGeneratingAiEmail]);

  useEffect(() => {
    const { isDirty } = state;
    let timeoutId: NodeJS.Timeout;

    if (isDirty) {
      timeoutId = setTimeout(() => {
        handleSaveContent();
        dispatch({
          type: 'SET_IS_DIRTY',
          payload: 0,
        });
      }, SAVING_TIMEOUT);
    }
    // Cleanup function to clear the timeout if the component unmounts or form data changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [state.isDirty]);

  async function onImproveDescription(selection: string, writingStyle: WritingStyle) {
    if (!handleImproveDescription) return;
    if (!selection) return;
    if (selection.length < 100) {
      toast.error('No selection. Please select at least 100 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 1000) {
      toast.error('No selection. Please select no more than 1000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      let count = 1;
      switch (writingStyle) {
        case WritingStyle.Expand:
          count = 1;
          break;
        case WritingStyle.Shorten:
          count = 1;
          break;
        case WritingStyle.Rewrite:
          count = 2;
          break;
        default:
          count = 1;
          break;
      }
      const result = await handleImproveDescription(selection, writingStyle, count);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  async function onFixTypo(selection: string) {
    if (!handleFixTypo) return;
    if (!selection) return;
    if (selection.length < 50) {
      toast.error('No selection. Please select at least 50 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }
    if (selection.length > 3000) {
      toast.error('No selection. Please select no more than 3000 characters to get AI suggestions on your selection.', {
        position: 'top-left',
      });
      return;
    }

    try {
      const result = await handleFixTypo(selection);
      return result;
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error('error', error);
      return '';
    }
  }

  async function handleDownloadPdf(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    if (!aiEmail) return;
    const subject = state?.subject;
    const serializedBody = serializeSlateNodeToHtml(state?.body);
    const html = `
          <html>
            <head>
              <style>
                body {
                  font-family: Arial, Helvetica, sans-serif;
                }
              </style>
            </head>
            <body>
              <h1>${subject}</h1>
              ${serializedBody}
            </body>
          </html>
        `;

    generatePdf(html, `${aiEmail?.category}.pdf`);
  }

  async function handleEmail(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();
    await handleSaveContent();

    try {
      const subject = state?.subject || '';
      const body = serializeSlateNodeToText(state?.body);
      const email = '';
      let params = subject || body ? '?' : '';
      if (subject) params += `subject=${encodeURIComponent(subject)}`;
      if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

      window.location.href = `mailto:${email}${params}`;
    } catch (error) {
      toast.error('Something went wrong. Please try again.', {
        position: 'top-left',
      });
    }
  }

  async function handleCopyText(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    event.preventDefault();

    await handleSaveContent();

    const content = state?.body;

    try {
      toast.success('Email copied to clipboard', {
        position: 'top-left',
      });
      const textContent = serializeSlateNodeToText(content);
      copy(textContent);
    } catch (error) {
      console.error('error', error);
    }
  }

  function handleContentChange(content: Descendant[]) {
    dispatch({
      type: 'UPDATE_BODY',
      payload: content,
    });
  }

  async function handleSaveContent(): Promise<void> {
    dispatch({
      type: 'SET_IS_SAVING',
      payload: true,
    });
    const { uuid } = await handleSave({
      uuid: state.uuid,
      category: state.category,
      subject: state.subject,
      body: state.body,
      contactEmail: state.contactEmail,
      contactName: state.contactName,
    });
    dispatch({
      type: 'SET_IS_SAVING',
      payload: false,
    });
    dispatch({
      type: 'SET_UUID',
      payload: uuid,
    });
  }

  async function onGenerateAIEmail(): Promise<void> {
    dispatch({
      type: 'SET_IS_LOADING',
      payload: true,
    });
    await handleGenerateAIEmail(category as EmailCategory);
  }

  const { uuid, subject, body, category, isSaving, isLoading, isDirty } = state || {};

  return (
    <Container>
      {/* <pre>{JSON.stringify({ state }, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ uuid }, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ isLoading }, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ isDirty }, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify({ isSaving }, null, 2)}</pre> */}

      <>
        <Header>
          <HeaderMain>
            <IconButton>
              <CloseIcon size={1.5} onClick={onCloseClick} />
            </IconButton>
            <div>
              {isLoading ? null : <Copy styleLevel={1}>{getCategoryTitle(category)}</Copy>}
              {isLoading ? null : <Copy styleLevel={2}>{subject}</Copy>}
            </div>
          </HeaderMain>
          <Pill color={Colors.Black} onClick={() => onGenerateAIEmail()}>
            <SparklingIcon color={Colors.Contrast} />
            Regenerate AI Email
          </Pill>
          {isLoading ? (
            <Pill color={Colors.PrimaryDarkest} fillColor={Colors.ContrastOverlay}>
              <strong>Loading...</strong>
            </Pill>
          ) : (
            <>
              {isSaving ? (
                <Pill color={Colors.PrimaryDarkest} fillColor={Colors.ContrastOverlay}>
                  <strong>Saving...</strong>
                </Pill>
              ) : isDirty ? (
                <Pill
                  color={Colors.PrimaryDarkest}
                  fillColor={Colors.ContrastOverlay}
                  onClick={() => handleSaveContent()}
                >
                  <strong>Unsaved</strong>
                </Pill>
              ) : (
                <Pill color={Colors.White} fillColor={Colors.Primary} onClick={() => handleSaveContent()}>
                  <strong>Saved</strong>
                </Pill>
              )}
            </>
          )}
        </Header>

        <TabController
          headers={[
            { id: 'write', label: 'Write' },
            { id: 'data', label: 'Data' },
          ]}
          initialActiveId="write"
        >
          <TabPanel $tabId="write">
            <Actions>
              <Action onClick={handleCopyText}>
                <CopyIcon size={1.5} />
                Copy Text
              </Action>
              <Action onClick={handleDownloadPdf}>
                <DownloadIcon size={1.4} />
                Download PDF
              </Action>
              <Action onClick={handleEmail}>
                <MailIcon size={1.5} />
                Email
              </Action>
            </Actions>
            <BackgroundEmailTemplate>
              {!isLoading ? (
                <EmailTemplate>
                  <EmailTempleBody>
                    <InputWrapper>
                      <Label>Subject</Label>
                      <Input
                        type="text"
                        placeholder="Subject"
                        value={subject}
                        onChange={(event) => {
                          event.preventDefault();
                          dispatch({
                            type: 'CHANGE_FORM_VALUE',
                            payload: { key: 'subject', value: event.target.value },
                          });
                        }}
                      />
                    </InputWrapper>
                    {body && body.length > 0 && body.length > 0 ? (
                      <SlateEditor
                        onImproveDescription={onImproveDescription}
                        onFixTypo={onFixTypo}
                        onChange={handleContentChange}
                        initialValue={body}
                      />
                    ) : (
                      <div>Loading...</div>
                    )}
                  </EmailTempleBody>
                </EmailTemplate>
              ) : (
                <CenteredLayout>
                  <Placeholder
                    lottie={<Lottie animationData={aiInProgressAnimation} />}
                    title="In progress"
                    description={
                      <>
                        <Copy marginBottom={16}>Our AI is generating the perfect email.</Copy>
                        <Copy styleLevel={3}>Hold on tight, it will be ready in a few seconds.</Copy>
                      </>
                    }
                  />
                </CenteredLayout>
              )}
            </BackgroundEmailTemplate>
          </TabPanel>
          <TabPanel $tabId="data">
            <Actions>
              <Action onClick={handleCopyText}>
                <CopyIcon size={1.5} />
                Copy Text
              </Action>
              <Action onClick={handleDownloadPdf}>
                <DownloadIcon size={1.4} />
                Download PDF
              </Action>
              <Action onClick={handleEmail}>
                <MailIcon size={1.5} />
                Email
              </Action>
            </Actions>
            <DesignContainer>
              <Form onSubmit={() => {}}>
                <TextInput
                  name="email"
                  type="text"
                  label="Email"
                  placeholder="Contact Email"
                  noMargin={true}
                  onChange={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: 'CHANGE_FORM_VALUE',
                      payload: { key: 'contactEmail', value: e.target.value },
                    });
                  }}
                  value={state.contactEmail || ''}
                />
                <TextInput
                  name="name"
                  type="text"
                  label="Name"
                  placeholder="Contact Name"
                  noMargin={true}
                  onChange={(e) => {
                    e.preventDefault();
                    dispatch({
                      type: 'CHANGE_FORM_VALUE',
                      payload: { key: 'contactName', value: e.target.value },
                    });
                  }}
                  value={state.contactName || ''}
                />
              </Form>
            </DesignContainer>
          </TabPanel>
        </TabController>
      </>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
`;

const HeaderMain = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const BackgroundEmailTemplate = styled.div`
  background-color: #f2f2f2;
  border: 1px solid #ccc;
`;

const EmailTemplate = styled.div`
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  max-width: 900px;
  padding: 16px 8px;
`;

const EmailTempleBody = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
`;

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  outline: none;
  width: 100%;
  &:focus {
    border-color: #333;
  }
`;

const Label = styled.label`
  font-size: 18x;
  font-weight: 500;
  color: #333;
`;

const getCategoryTitle = (category: EmailCategory | undefined | null) => {
  switch (category) {
    case EmailCategory.FollowUp:
      return 'Follow Up';
    case EmailCategory.Consent:
      return 'Consent';
    case EmailCategory.ThankYou:
      return 'Thank You';
    default:
      return '';
  }
};

const DesignContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 8px;
  padding: 16px;
  position: relative;
  border: 1px solid ${Colors.ContrastLightest};
`;
