import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationUpdateCoverLetterArgs } from '../../generated/types';

export const UPDATE_COVER_LETTER = gql`
  mutation UpdateCoverLetter(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $coverLetterUuid: String!
    $state: JSON!
  ) {
    updateCoverLetter(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      coverLetterUuid: $coverLetterUuid
      state: $state
    ) {
      uuid
      state
      isDeleted
      createdAt
      updatedAt
    }
  }
`;

export const useUpdateCoverLetter = (
  options?: MutationHookOptions<Pick<Mutation, 'updateCoverLetter'>, MutationUpdateCoverLetterArgs>
) =>
  useMutation<Pick<Mutation, 'updateCoverLetter'>, MutationUpdateCoverLetterArgs>(UPDATE_COVER_LETTER, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const updateAICoverLetter = data?.updateCoverLetter;
      const updateAICoverLetterUuid = updateAICoverLetter?.uuid;

      if (!jobUuid) return;
      if (!updateAICoverLetter) return;
      if (!updateAICoverLetterUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          coverLetters(existingItems = []) {
            return existingItems.map((item: any) => {
              if (item.uuid === updateAICoverLetterUuid) {
                return updateAICoverLetter;
              } else {
                return item;
              }
            });
          },
        },
      });
    },
    ...options,
  });
