import { ResumeContactFormProps, ResumeContactFormSimpleProps } from './ResumeContactForm.types';
import { Container, FullItem, GroupItems, HalfItem } from '../ResumeCommonForm';
import { TextInput } from '../../atoms/Form/TextInput';
import { PlacesAutocompleteInput } from '../../atoms/Form/PlacesAutocompleteInput';
import { ErrorBoundary } from '../ErrorBoundary';

export const ResumeContactFormSimple = ({ dispatch, contacts }: ResumeContactFormSimpleProps) => {
  const { email = '', phone = '', country = '', city = '', linkedin = '' } = contacts;
  return (
    <ErrorBoundary message="Molecule\ResumeContactForm">
      <Container>
        <GroupItems>
          <HalfItem>
            <TextInput
              name="email"
              type="text"
              label="Email"
              placeholder="e.g. John@me.com"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.email', value: e.target.value },
                });
              }}
              value={email}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="phone"
              type="text"
              label="Phone"
              placeholder="e.g. +1 123 456 7890"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.phone', value: e.target.value },
                });
              }}
              value={phone}
            />
          </HalfItem>
          <FullItem>
            <TextInput
              name="linkedin"
              type="text"
              label="linkedin"
              placeholder=""
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.linkedin', value: e.target.value },
                });
              }}
              value={linkedin}
            />
          </FullItem>
          {/* <FullItem>
            <PlacesAutocompleteInput
              defaultValue={''}
              name="location"
              label="Location"
              placeholder="Where are you living?"
              noMargin={true}
              setAddresses={(address) => {
                const { postCode, street, city, country } = address;
                if (postCode) {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'contacts.postCode', value: postCode },
                  });
                }
                if (street) {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'contacts.address', value: street },
                  });
                }
                if (city) {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'contacts.city', value: city },
                  });
                }
                if (country) {
                  dispatch({
                    type: 'CHANGE_FORM_VALUE',
                    payload: { key: 'contacts.country', value: country },
                  });
                }
              }}
            />
          </FullItem> */}
          <HalfItem>
            <TextInput
              name="country"
              type="text"
              label="Country"
              placeholder="e.g. United Kingdom"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.country', value: e.target.value },
                });
              }}
              value={country}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="city"
              type="text"
              label="City"
              placeholder="e.g. London"
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.city', value: e.target.value },
                });
              }}
              value={city}
            />
          </HalfItem>
          {/* <HalfItem>
            <TextInput
              name="address"
              type="text"
              label="Address"
              placeholder=""
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.address', value: e.target.value },
                });
              }}
              value={address}
            />
          </HalfItem>
          <HalfItem>
            <TextInput
              name="postCode"
              type="text"
              label="Post Code"
              placeholder=""
              noMargin={true}
              onChange={(e) => {
                e.preventDefault();
                dispatch({
                  type: 'CHANGE_FORM_VALUE',
                  payload: { key: 'contacts.postCode', value: e.target.value },
                });
              }}
              value={postCode}
            />
          </HalfItem> */}
        </GroupItems>
      </Container>
    </ErrorBoundary>
  );
};
