import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { Task } from '../../../generated/types';
import { TaskManagerFullTextInput, TaskManagerTextInput } from './TaskManagerTextInput';
import { IconButton } from '../../atoms/Icons/Icon';
import { DeleteIcon } from '../../atoms/Icons';
import { Pill } from '../../atoms/Pill';
import { Colors } from '../../../styles/colors';
import { formatDate } from '../../../utils/date';
import { media } from '../../../styles/media';

export const TaskManagerItemWithAI = ({
  index,
  task,
  handleCompleteTask,
  handleDeleteTask,
  handleEditTask,
}: {
  index: number;
  task: Task;
  handleCompleteTask: any;
  handleDeleteTask: any;
  handleEditTask: any;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isCompleted, setIsCompleted] = useState(task.isCompleted);
  const [isDeleleted, setIsDeleleted] = useState(false);

  const deletetask = async (uuid: string): Promise<void> => {
    setIsDeleleted(true);
    await handleDeleteTask(uuid);
  };

  const completeTask = async (event: any, uuid: string): Promise<void> => {
    const isCompleted = event.target.checked;
    setIsCompleted(isCompleted);
    await handleCompleteTask(uuid, isCompleted);
  };

  const updateTask = async (uuid: string, value: any): Promise<void> => {
    setIsEditing(false);
    await handleEditTask(uuid, value);
  };

  const handleDoubleClick = () => setIsEditing(true);

  return (
    <>
      {!isDeleleted && (
        <motion.div custom={{ delay: (index + 1) * 0.1 }} initial="hidden" animate="visible" exit="hidden">
          {isEditing ? (
            <TaskManagerFullTextInput
              // defaultValue={title as string}
              task={task}
              onSave={(value) => {
                updateTask(task.uuid, value);
              }}
              onCancel={() => setIsEditing(false)}
            />
          ) : (
            <Wrapper>
              {/* <pre>{JSON.stringify(task, null, 2)}</pre> */}
              <WrapperLeft>
                <Checkout
                  type="checkbox"
                  readOnly
                  onClick={(event) => completeTask(event, task.uuid)}
                  checked={isCompleted}
                />
                <Label className={isCompleted ? 'completed' : ''} onDoubleClick={handleDoubleClick}>
                  <LabelMain>
                    <LabelTitle>{task.title}</LabelTitle>
                    <LabelDescription>{task.description}</LabelDescription>
                  </LabelMain>
                  <LabelExtra>
                    <LabelExtraDueDate>{getDueDate(task.dueDate, task.isFullDay)}</LabelExtraDueDate>
                    <LabelExtraPriority>{getPriority(task.priority)}</LabelExtraPriority>
                  </LabelExtra>
                </Label>
              </WrapperLeft>
              <WrapperRight>
                <IconButton onClick={() => deletetask(task.uuid)}>
                  <DeleteIcon />
                </IconButton>
              </WrapperRight>
            </Wrapper>
          )}
        </motion.div>
      )}
    </>
  );
};

const getDueDate = (dueDate: string, isFullDay?: boolean | null): string => {
  let date = '';
  if (dueDate) {
    const isToday = new Date(dueDate).toDateString() === new Date().toDateString();
    const isTomorrow =
      new Date(dueDate).toDateString() === new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toDateString();
    if (isToday) {
      date = 'Today';
    } else if (isTomorrow) {
      date = 'Tomorrow';
    } else {
      date = formatDate({
        date: dueDate,
        format: 'short',
      });
    }

    if (!isFullDay) {
      date = `${date} ${getTime(dueDate)}`;
    }
  }

  return date;
};

const getTime = (dueDate: string): string => {
  if (!dueDate) {
    return '';
  }
  return formatDate({
    date: dueDate,
    format: 'time',
  });
};

const getPriority = (priority?: string | null): React.ReactNode => {
  let priorityElement = <></>;
  switch (priority) {
    case 'CRITICAL':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.ContrastDarkest} color={Colors.Black}>
          High
        </Pill>
      );
      break;
    case 'HIGH':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.Contrast} color={Colors.Black}>
          High
        </Pill>
      );
      break;
    case 'MEDIUM':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.Primary} color={Colors.Black}>
          Medium
        </Pill>
      );
      break;
    case 'LOW':
      priorityElement = (
        <Pill isSmall={true} fillColor={Colors.PrimaryLightest} color={Colors.Black}>
          Low
        </Pill>
      );
      break;
    default:
      break;
  }
  return priorityElement;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  &:hover {
    svg {
      display: block;
    }
  }

  ${media.from_tablet`
    svg {
      display: none;
    }
  `}
`;

const WrapperLeft = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const WrapperRight = styled.div`
  display: flex;
  width: 48px;
  justify-content: center;
`;

const Checkout = styled.input`
  position: absolute;
  border: 1px solid red;
  opacity: 0;
  border: 1px solid red;
  height: 64px;
  width: 48px;
  cursor: pointer;
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 16px 8px 52px;
  background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23949494%22%20stroke-width%3D%223%22/%3E%3C/svg%3E);
  background-repeat: no-repeat;
  background-position: center left;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  background-color: ${Colors.ContrastLightest};
  border-radius: 8px;
  width: 100%;
  flex-wrap: wrap;

  &.completed {
    background-image: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%2359A193%22%20stroke-width%3D%223%22%2F%3E%3Cpath%20fill%3D%22%233EA390%22%20d%3D%22M72%2025L42%2071%2027%2056l-4%204%2020%2020%2034-52z%22%2F%3E%3C%2Fsvg%3E); */
  }
`;

const LabelMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const LabelTitle = styled.div`
  font-size: 24px;
`;

const LabelDescription = styled.div`
  font-size: 1rem;
  color: ${Colors.BlackLightest};
`;

const LabelExtra = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;
`;

const LabelExtraDueDate = styled.div`
  color: ${Colors.ContrastDarkest};
`;

const LabelExtraPriority = styled.div``;
