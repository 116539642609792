import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationArchiveAiEmailArgs } from '../../generated/types';

export const ARCHIVE_AI_EMAIL = gql`
  mutation ArchiveAIEmail(
    $userUuid: String!
    $boardUuid: String!
    $jobUuid: String!
    $aiEmailUuid: String!
    $isDeleted: Boolean!
  ) {
    archiveAIEmail(
      userUuid: $userUuid
      boardUuid: $boardUuid
      jobUuid: $jobUuid
      aiEmailUuid: $aiEmailUuid
      isDeleted: $isDeleted
    ) {
      uuid
      isDeleted
    }
  }
`;

export const useArchiveAIEmail = (
  options?: MutationHookOptions<Pick<Mutation, 'archiveAIEmail'>, MutationArchiveAiEmailArgs>
) =>
  useMutation<Pick<Mutation, 'archiveAIEmail'>, MutationArchiveAiEmailArgs>(ARCHIVE_AI_EMAIL, {
    update(cache, { data = {} }, options) {
      if (!data) return;
      if (!options) return;
      const jobUuid = options?.variables?.jobUuid;
      const archiveAIEmail = data?.archiveAIEmail;
      const archiveAIEmailUuid = archiveAIEmail?.uuid;

      if (!jobUuid) return;
      if (!archiveAIEmail) return;
      if (!archiveAIEmailUuid) return;

      cache.modify({
        id: cache.identify({
          __typename: 'Job',
          uuid: jobUuid,
        }),
        fields: {
          aiEmails(existingTasks = [], { readField }) {
            return [...existingTasks].filter((taskRef) => {
              const uuid = readField('uuid', taskRef);
              return uuid !== archiveAIEmailUuid;
            });
          },
        },
      });
    },
    ...options,
  });
