import styled from 'styled-components';

import { AIEmailListItemProps } from './AIEmailListItem.types';
import { Container, ContainerLeft, ContainerRight, LineWrapper } from './AIEmailListItem.styles';
import { IconButton } from '../../atoms/Icons/Icon';
import { DeleteIcon, DownloadIcon, EditIcon, HeartIcon, LawIcon, MailIcon } from '../../atoms/Icons';
import { formatDate } from '../../../utils/date';
import { Copy } from '../../atoms/Typography';
import { Colors } from '../../../styles/colors';
import { EmailCategory } from '../../../generated/types';
import { serializeSlateNodeToHtml } from '../SlateEditor/helper';
import { generatePdf } from '../../../utils/pdfMake';

export const AIEmailListItem = ({
  aiEmail,
  onEditClick,
  onDeleteClick,
}: React.PropsWithChildren<AIEmailListItemProps>) => {
  const { subject, createdAt, updatedAt, category } = aiEmail;

  return (
    <Container>
      <ContainerLeft>
        <LineWrapper>
          {getIcon(category)}
          <div>
            <Copy isBold={true}>{getCategoryTitle(category)}</Copy>
            <Copy styleLevel={2}>{subject}</Copy>
          </div>
        </LineWrapper>
        <Copy styleLevel={3} color={Colors.GreyDarkest}>
          Last modified on{' '}
          {formatDate({
            date: updatedAt ? updatedAt : createdAt,
            format: 'shortdatetime',
          })}
        </Copy>
      </ContainerLeft>
      <ContainerRight>
        <IconButton onClick={() => onEditClick()}>
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            if (!aiEmail) return;
            const subject = aiEmail?.subject;
            const serializedBody = serializeSlateNodeToHtml(aiEmail?.body);
            const html = `
                  <html>
                    <head>
                      <style>
                        body {
                          font-family: Arial, Helvetica, sans-serif;
                        }
                      </style>
                    </head>
                    <body>
                      <h1>${subject}</h1>
                      ${serializedBody}
                    </body>
                  </html>
                `;
            generatePdf(html, `${category}.pdf`);
          }}
        >
          <DownloadIcon />
        </IconButton>
        <IconButton>
          <DeleteIcon onClick={() => onDeleteClick()} />
        </IconButton>
      </ContainerRight>
    </Container>
  );
};

export const AIEmailList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const getCategoryTitle = (category: EmailCategory | undefined | null) => {
  switch (category) {
    case EmailCategory.FollowUp:
      return 'Follow Up';
    case EmailCategory.Consent:
      return 'Consent';
    case EmailCategory.ThankYou:
      return 'Thank You';
    default:
      return '';
  }
};

const getIcon = (category: EmailCategory | undefined | null) => {
  switch (category) {
    case EmailCategory.FollowUp:
      return <MailIcon size={3} />;
    case EmailCategory.Consent:
      return <LawIcon size={3} />;
    case EmailCategory.ThankYou:
      return <HeartIcon size={3} />;
    default:
      return <MailIcon size={3} />;
  }
};
