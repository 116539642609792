import styled from 'styled-components';
import { media } from '../../../styles/media';

export const Container = styled.div`
  height: 100%;
  width: 1600px;
  max-width: 100%;
  /* display: flex;
  border: 5px solid green; */
  /* ${media.from_tablet`
    width: 800px;
  `}
  ${media.from_laptop`
    width: 900px;
  `}
  ${media.from_large_laptop`
    width: 1000px;
  `} */
`;

export const TwoColumnsLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${media.from_tablet`
    flex-direction: row;
  `}
`;

export const LeftColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
`;

export const RightColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 190px 16px 0 16px;
  padding: 0 16px 0 16px;
  ${media.from_tablet`
    padding: 190px 16px 0 16px;
  `}
`;

export const CenteredLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
